<template>
    <div v-if="observations && observations.length > 0" class="nopads">
        <b-row>
            <span class="section-title">{{ $t('observations.observations') }} ({{observations.length}})</span>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : observationsVisible}"
                    v-b-toggle.observationDetails
                    @click.stop="observationsVisible = !observationsVisible"/>
            </b-col>
        </b-row>
        <b-collapse
            id="observationDetails"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- TODO - HARMONIZE EDITOR PLACEMENT -->
            <contract-observations-list
                :observations="observations"
                :observation-types="observationTypes"
                :params="searchParams"
                @observationSelected="editObservation"/>
        </b-collapse>
        <hr class="report-divider nopads"/>
        <transition name="fade">
            <observation-editor
                v-if="observation != null"
                id="editor"
                :observation="observation"
                :observations="[observation]"
                :is-observer="isObserver"
                @close="observation = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import ContractObservationsList from "@/components/observation/ContractObservationsList";
import ObservationEditor from "@/components/observation/ObservationEditor";

export default {
    name: "contract-report-observations",
    components: {ObservationEditor, ContractObservationsList},
    mixins: [restApi],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            observations: [],
            observationTypes: [],
            observation: null,
            observationsVisible: false
        }
    },
    watch: {
        searchParams: function () {
            this.fetchObservations()
        }
    },
    mounted() {
        this.fetchObservations()
    },
    methods: {
        fetchObservations: function () {
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                this.observations = []
                this.$emit('loading', true)
                let params = {
                    from: new Date(this.searchParams.dateFrom).toISOString(),
                    to: new Date(this.searchParams.dateTo).toISOString(),
                    contract: this.searchParams.contract
                }
                this.restFetchParams(this.observationUrl, params, this.handleResponse, this.handleFail)
            }
        },
        handleResponse: function (response) {
            this.$emit('loading', false);
            this.observations = response.data;
            if (this.observations && this.observations.length > 0) {
                this.observations.forEach((observation) => {
                    if (!this.observationTypes.some(type => type.id === observation.type.id)) {
                        this.observationTypes.push(observation.type);
                    }
                });
            }
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        closeAndUpdate() {
            this.observation = null
            this.fetchObservations()
        },
        editObservation(observation) {
            this.observation = this.observations.find(item => item.id === observation.id)
        }
    }
}
</script>
