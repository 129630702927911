import {mapHelper} from "@/components/mixins/MapMixin";

export const units = {
    KILOGRAMS: 'kg',
    TONS: 'tn',
    METERS: 'jm',
    SQUARE_METERS: 'm2',
    CUBIC_METERS: 'm3',
    KILOGRAMS_PER_SQUARE_METRE: 'kg/m2',
    KILOGRAMS_PER_CUBIC_METRE: 'kg/m3',
    KILOMETERS: 'km',
    PCS: 'kpl',
    HOURS: 'h'
}

export const material = {
    ALUMINIUM: 1,
    PLYWOOD: 2,
    GLAS: 3,
    WOOD: 4,
    STEEL: 5,
    CAST_IRON: 6,
    GROUND_SUBSTANCE: 7,
    OTHER: 8,
    CONCRETE: 9,
    PLASTIC: 10,
    ROCK: 12,
    PLEXIGLASS: 13,
    GLASS_FIBER: 14,
    ANIMAL_NET: 15,
    ARMOR_MESH: 16,
    METAL: 17,
    ASPHALT: 18,
    ALUMINIUM_COMPOSITE: 19
}

export const equipmentOidTypes = {
    EXTERNAL_FENCES: "1.2.246.578.4.3.2.",
    EXTERNAL_RAILINGS: "1.2.246.578.4.3.1.",
    EXTERNAL_DRAINS: "1.2.246.578.4.3.12.",
    EXTERNAL_TRAFFIC_SIGNS: "1.2.246.578.4.3.15.",
    EXTERNAL_PORTALS: "1.2.246.578.4.3.5.",
    EXTERNAL_STAIRS: "1.2.246.578.4.3.9.",
    EXTERNAL_GATES: "1.2.246.578.4.3.4.",
    EXTERNAL_BOOM_BARRIER_BOLLARD: "1.2.246.578.4.3.10.",
    EXTERNAL_PIPE_WIRE_CABLE: "1.2.246.578.4.3.13.",
    EXTERNAL_POSTS: "1.2.246.578.4.3.14.",
    EXTERNAL_EDGE_PILES: "1.2.246.578.4.3.3.",
    EXTERNAL_EDGE_SUPPORTS: "1.2.246.578.4.3.7.",
    EXTERNAL_CULVERTS: "1.2.246.578.4.3.6.",
    EXTERNAL_ROADSIDE_FURNITURE: "1.2.246.578.4.3.11.",
    EXTERNAL_ROADSIDE_ADVERTISEMENTS: "1.2.246.578.4.3.16.",
    EXTERNAL_LIGHTINGS: "1.2.246.578.4.3.8."
}

export const equipmentActions = {
    ADDITION: 1,
    INSPECTION: 2,
    CLEANING: 3,
    REPAIR: 4,
    REMOVAL: 5,
    REPLACE: -1
}


export const conversionModelHelper = {
    mixins: [mapHelper],
    methods: {
        newConversionModel: function () {
            return {
                id: -1,
                unit: null,
                code: null,
                pricing_model: null,
                price_list: null
            }
        },
        newTaskTypeConversionModel: function () {
            return {
                id: -1,
                code: null,
                task_type: null,
            }
        },
        jsonToItem: function (item) {
            return JSON.parse(JSON.stringify(item))
        },
        getMaterials: function () {
            return [
                {name: this.getMaterialString(material.ALUMINIUM), value: material.ALUMINIUM},
                {name: this.getMaterialString(material.PLYWOOD), value: material.PLYWOOD},
                {name: this.getMaterialString(material.GLAS), value: material.GLAS},
                {name: this.getMaterialString(material.WOOD), value: material.WOOD},
                {name: this.getMaterialString(material.STEEL), value: material.STEEL},
                {name: this.getMaterialString(material.CAST_IRON), value: material.CAST_IRON},
                {name: this.getMaterialString(material.GROUND_SUBSTANCE), value: material.GROUND_SUBSTANCE},
                {name: this.getMaterialString(material.OTHER), value: material.OTHER},
                {name: this.getMaterialString(material.CONCRETE), value: material.CONCRETE},
                {name: this.getMaterialString(material.PLASTIC), value: material.PLASTIC},
                {name: this.getMaterialString(material.ROCK), value: material.ROCK},
                {name: this.getMaterialString(material.PLEXIGLASS), value: material.PLEXIGLASS},
                {name: this.getMaterialString(material.GLASS_FIBER), value: material.GLASS_FIBER},
                {name: this.getMaterialString(material.ANIMAL_NET), value: material.ANIMAL_NET},
                {name: this.getMaterialString(material.ARMOR_MESH), value: material.ARMOR_MESH},
                {name: this.getMaterialString(material.METAL), value: material.METAL},
                {name: this.getMaterialString(material.ASPHALT), value: material.ASPHALT},
                {name: this.getMaterialString(material.ALUMINIUM_COMPOSITE), value: material.ALUMINIUM_COMPOSITE},
            ]
        },
        getMaterialString: function (code) {
            switch (code) {
                case material.ALUMINIUM:
                    return this.$t('materials.aluminium')
                case material.PLYWOOD:
                    return this.$t('materials.plywood')
                case material.GLAS:
                    return this.$t('materials.glas')
                case material.WOOD:
                    return this.$t('materials.wood')
                case material.STEEL:
                    return this.$t('materials.steel')
                case material.CAST_IRON:
                    return this.$t('materials.cast_iron')
                case material.GROUND_SUBSTANCE:
                    return this.$t('materials.ground_substance')
                case material.CONCRETE:
                    return this.$t('materials.concrete')
                case material.PLASTIC:
                    return this.$t('materials.plastic')
                case material.ROCK:
                    return this.$t('materials.rock')
                case material.PLEXIGLASS:
                    return this.$t('materials.plexiglass')
                case material.GLASS_FIBER:
                    return this.$t('materials.glass_fiber')
                case material.ANIMAL_NET:
                    return this.$t('materials.animal_net')
                case material.ARMOR_MESH:
                    return this.$t('materials.armor_mesh')
                case material.METAL:
                    return this.$t('materials.metal')
                case material.ASPHALT:
                    return this.$t('materials.asfalt')
                case material.ALUMINIUM_COMPOSITE:
                    return this.$t('materials.al_composite')
                case material.OTHER:
                    return this.$t('common.other')
                default:
                    return ""
            }
        },
        getExternalSystemName(id) {
            if (id === 1){
                return 'Velho'
            }
            return '-'
        },
        getTypeCodeForKey(key) {
            switch (key) {
                case "road_signs": return this.EXTERNAL_TRAFFIC_SIGNS
                case "railings": return this.EXTERNAL_RAILINGS
                case "posts": return this.EXTERNAL_POSTS
                case "portals": return this.EXTERNAL_PORTALS
                case "culverts": return this.EXTERNAL_CULVERTS
                case "fences": return this.EXTERNAL_FENCES
                case "drains": return this.EXTERNAL_DRAINS
            }
            return -1 // Unknown
        },
        getEquipmentTypeName(type) {
            switch (type) {
                case this.EXTERNAL_RAILINGS: return this.$t('railing.editor_title')
                case this.TRAFFIC_SIGN:
                case this.EXTERNAL_TRAFFIC_SIGNS: return this.$t('road_sign.road_sign')
                case this.EXTERNAL_POSTS: return this.$t('post.title')
                case this.EXTERNAL_PORTALS: return this.$t('portal.portal')
                case this.EXTERNAL_CULVERTS: return this.$t('culvert.culvert')
                case this.EXTERNAL_FENCES: return this.$t('fence.editor_title')
                case this.EXTERNAL_DRAINS: return this.$t('drain.drain')
                case this.EXTERNAL_EDGE_SUPPORTS: return this.$t('edge_support.editor_title')
                case this.EXTERNAL_EDGE_PILES: return this.$t('edge_pile.editor_title')
                case this.EXTERNAL_GATES: return this.$t('gate.editor_title')
                case this.EXTERNAL_STAIRS: return this.$t('stair.editor_title')
                case this.EXTERNAL_LIGHTING: return this.$t('lighting.editor_title')
                case this.EXTERNAL_ROADSIDE_FURNITURE: return this.$t('roadside_furniture.editor_title')
                case this.EXTERNAL_ROADSIDE_ADVERTISEMENTS: return this.$t('roadside_advertisement.editor_title')
                case this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS: return this.$t('boom_barrier_bollard.editor_title')
                case this.EXTERNAL_PIPES_WIRES_AND_CABLES: return this.$t('pipe_wire_cable.editor_title')
            }
            return this.$t('common.unknown')
        },
        getVelhoEquipmentTypeOid(oid) {
            switch (true) {
                case oid.startsWith(equipmentOidTypes.EXTERNAL_FENCES):
                    return this.getEquipmentTypeName(this.EXTERNAL_FENCES);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_RAILINGS):
                    return this.getEquipmentTypeName(this.EXTERNAL_RAILINGS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_DRAINS):
                    return this.getEquipmentTypeName(this.EXTERNAL_DRAINS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_TRAFFIC_SIGNS):
                    return this.getEquipmentTypeName(this.EXTERNAL_TRAFFIC_SIGNS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_PORTALS):
                    return this.getEquipmentTypeName(this.EXTERNAL_PORTALS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_STAIRS):
                    return this.getEquipmentTypeName(this.EXTERNAL_STAIRS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_GATES):
                    return this.getEquipmentTypeName(this.EXTERNAL_GATES);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_BOOM_BARRIER_BOLLARD):
                    return this.getEquipmentTypeName(-1);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_PIPE_WIRE_CABLE):
                    return this.getEquipmentTypeName(-1);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_POSTS):
                    return this.getEquipmentTypeName(this.EXTERNAL_POSTS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_EDGE_PILES):
                    return this.getEquipmentTypeName(this.EXTERNAL_EDGE_PILES);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_EDGE_SUPPORTS):
                    return this.getEquipmentTypeName(this.EXTERNAL_EDGE_SUPPORTS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_CULVERTS):
                    return this.getEquipmentTypeName(this.EXTERNAL_CULVERTS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_ROADSIDE_FURNITURE):
                    return this.getEquipmentTypeName(this.EXTERNAL_ROADSIDE_FURNITURE);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_ROADSIDE_ADVERTISEMENTS):
                    return this.getEquipmentTypeName(this.EXTERNAL_ROADSIDE_ADVERTISEMENTS);
                case oid.startsWith(equipmentOidTypes.EXTERNAL_LIGHTINGS):
                    return this.getEquipmentTypeName(this.EXTERNAL_LIGHTINGS);
                default:
                    return this.getEquipmentTypeName(-1);
            }
        },
        getEquipmentActions() {
            return [
                {name: this.getActionString(equipmentActions.INSPECTION), value: equipmentActions.INSPECTION},
                {name: this.getActionString(equipmentActions.REPAIR), value: equipmentActions.REPAIR},
                {name: this.getActionString(equipmentActions.CLEANING), value: equipmentActions.CLEANING},
                {name: this.getActionString(equipmentActions.REPLACE), value: equipmentActions.REPLACE},
            ]
        },
        getActionString(code) {
            switch (code) {
                case equipmentActions.INSPECTION: return this.$t('equipment_actions.inspection');
                case equipmentActions.REPAIR: return this.$t('equipment_actions.repair');
                case equipmentActions.CLEANING: return this.$t('equipment_actions.cleaning');
                case equipmentActions.REPLACE: return this.$t('equipment_actions.replace');
            }
            return '-'
        },
        getEquipmentIcons() {
            const icons = []
            icons[this.EXTERNAL_RAILINGS] = require('@/assets/icon_railing.png');
            icons[this.EXTERNAL_POSTS] = require('@/assets/icon_post.png');
            icons[this.EXTERNAL_PORTALS] = require('@/assets/icon_portal.png');
            icons[this.EXTERNAL_CULVERTS] = require('@/assets/icon_culvert.png');
            icons[this.EXTERNAL_FENCES] = require('@/assets/icon_fence.png');
            icons[this.EXTERNAL_GATES] = require('@/assets/icon_gate.png');
            icons[this.EXTERNAL_EDGE_SUPPORTS] = require('@/assets/icon_edge_stone.png');
            icons[this.EXTERNAL_EDGE_PILES] = require('@/assets/icon_edge_pile.png');
            icons[this.EXTERNAL_STAIRS] = require('@/assets/icon_stairs.png');
            icons[this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS] = require('@/assets/icon_boom.png');
            icons[this.EXTERNAL_ROADSIDE_FURNITURE] = require('@/assets/icon_equipment.png');
            icons[this.EXTERNAL_ROADSIDE_ADVERTISEMENTS] = require('@/assets/icon_road_ad.png');
            icons[this.EXTERNAL_PIPES_WIRES_AND_CABLES] = require('@/assets/icon_pipes.png');
            icons[this.EXTERNAL_DRAINS] = require('@/assets/icon_drain.png');
            return icons
        },
        getEquipmentIcon(type) {
            const icons = this.getEquipmentIcons()
            if (icons[type]) {
                return icons[type]
            }
            return null
        }
    }
}
