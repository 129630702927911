<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('spreader_data.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <spreader-data-search
                    @search="fetchSpreaderData"
                />
            </keep-alive>
            <transition v-if="results && results.length > 0" name="fade">
                <spreader-material-charts :results="results"/>
            </transition>
            <spreader-data-list
                :results="results"
                @rowSelected="handleRowSelection"
            />
            <!-- Map modal for showing points -->
            <b-modal
                v-model="mapVisible"
                size="xl"
                modal-class="nooverflow"
                body-class="p-0 m-0"
                :ok-title="this.$t('common.close')"
                :ok-only="true"
                ok-variant="primary"
                :hide-header="true"
            >
                <div
                    class="trip-results"
                    style="height:70vh !important;"
                >
                    <!-- Map for showing trip here -->
                    <map-container
                        ref="mapContainer"
                        :find-user="false"
                        @onMapChanged="mapChanged"
                        @onMarkerTap="showPointDetails"
                    />
                    <spreader-data-map
                        v-if="map"
                        :spreader-data="details"
                        :map="map"
                    />
                </div>
            </b-modal>
            <b-modal
                v-model="harjaSenderVisible"
                size="xl"
                dialog-class="harja-sender-modal"
                modal-class="nooverflow"
                body-class="p-0 m-0"
                :hide-footer="true"
                :title="$t('spreader_data.send_to_harja_title')"
            >
                <spreader-data-harja-sender 
                    :user="this.user"
                    :isAdmin="this.isAdmin"
                    :item="spreaderDataToharjaItem"
                    :vehicleId="spreaderDataToharjaItem.vehicle ? spreaderDataToharjaItem.vehicle.id : null"
                    @close="closeHarjaSender"/>
            </b-modal>

           <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import SpreaderDataSearch from "@/components/materials/spreaderdata/SpreaderDataSearch";
import SpreaderDataList from "@/components/materials/spreaderdata/SpreaderDataList";
import SpreaderDataHarjaSender from "@/components/materials/spreaderdata/SpreaderDataHarjaSender";
import {restApi} from "@/components/mixins/RestApiMixin";
import SpreaderMaterialCharts from "@/components/materials/spreaderdata/SpreaderMaterialCharts";
import MapContainer from "@/components/map/MapContainer";
import SpreaderDataMap from "@/components/materials/spreaderdata/SpreaderDataMap";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {mapHelper} from "@/components/mixins/MapMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'SpreaderData',
    components: {
        SpreaderDataMap,
        MapContainer,
        SpreaderMaterialCharts,
        SpreaderDataList,
        SpreaderDataSearch,
        SpreaderDataHarjaSender
    },
    mixins: [restApi, spreaderDataMixin, mapHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            results: [],
            details: null,
            loading: false,
            mapVisible: false,
            harjaSenderVisible: false,
            spreaderDataToharjaItem: {},
            map: null,
            lastSearchedParams: null,
        }
    },
    mounted: function () {
        this.lastSearchedParams = {}
    },
    methods: {
        fetchSpreaderData: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.spreaderDataUrl, this.lastSearchedParams, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },
        handleRowSelection: function (item, openHarjaSender = false) {
            if(openHarjaSender) {
                this.spreaderDataToharjaItem = item
                this.harjaSenderVisible = true
            } else {
                this.showDataOnMap(item)
            }
        },
        showDataOnMap: function (item) {
            this.loading = true
            this.restFetch(this.spreaderDataUrl+"/"+item.id, this.handleDetails)
        },

        handleDetails(response) {
            this.loading = false
            if (response && response.data) {
                this.details = response.data
                this.mapVisible = true
            }
        },
        closeHarjaSender: function () {
            this.spreaderDataToharjaItem = {}
            this.harjaSenderVisible = false
        },
        mapChanged(map){
            this.$nextTick(() => this.map = map)
        },

        showPointDetails(data) {
            const pointItem = this.details.point_data.find(item => data.id === item.id)
            let bubbleData = []
            bubbleData[this.$t('spreader_data.spreading_width_dry')] = pointItem.spreading_width_dry + ' cm'
            bubbleData[this.getMaterialName(this.details.material_dry)] = pointItem.material_dry_amount + ' ' + this.details.material_dry_unit
            bubbleData[this.$t('spreader_data.spreading_width_liquid')] = pointItem.spreading_width_liquid + ' cm'
            bubbleData[this.getMaterialName(this.details.material_liquid)] = pointItem.material_liquid_amount + ' ' + this.details.material_liquid_unit
            this.map.showInfoBubble(this.SPREADER_DATA, data.id, this.toLocalTime(pointItem.time), bubbleData, pointItem.position.y, pointItem.position.x )
        }

    }
}
</script>
<style scoped>
    .nooverflow {
        overflow: hidden;
    }
    .harja-sender-modal {
        /** Show Bus event aler banner top of the modal */
        z-index: 4999 !important;
    }
</style>
