<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pl-1 pr-1">
        <span class="span-title">
            {{ $t('observation_filter.observation_type') }}
        </span>
        <b-row v-if="observations.length > 0" class="nopads">
            <div class="col-sm-6 nopads pr-2">
                <multiselect
                    v-model="selectedObservationTypes"
                    class="multiselect_routa"
                    :placeholder="$t('observation_filter.select_observation_type')"
                    :deselect-label="$t('common.remove_selection')"
                    :select-label="$t('common.select')"
                    :close-on-select="false"
                    :multiple="true"
                    label="name"
                    track-by="id"
                    :searchable="true"
                    :internal-search="true"
                    :selected-label="$t('common.selected')"
                    :options="observationTypes"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                        </span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('common.not_found') }}</span>
                    <span slot="noOptions">{{ $t('common.no_results') }}</span>
                </multiselect>
            </div>
            <div class="col-sm-6 nopads text-right">
                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="observations.length < 1"
                    class="result-button"
                    @click="exportExcel"
                >
                    {{ $t('trip.download_excel') }}
                </b-button>

                <b-button
                    size="sm"
                    variant="outline-success"
                    :disabled="observations.length < 1"
                    class="result-button ml-2"
                    @click="exportRoadReport"
                >
                    {{ $t('observations.download_road_report') }}
                </b-button>
            </div>
        </b-row>
        <!--Result table-->
        <transition name="fade">
            <div class="col-sm-12 list-content result-table">
                <b-table
                    small
                    id="resultTable"
                    ref="resultTable"
                    :items="tableItems"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="onRowClicked"
                >
                    <template v-slot:cell(created_time)="row">
                        {{ getDateString(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {downloadHelper} from '../mixins/DownloadMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {EventBus} from "@/event-bus";

export default {
    name: 'ContractObservationsList',
    mixins: [timeUtils, restApi, vehicleHelper, downloadHelper],
    props: {
        observations: {
            type: Array,
            default() {
                return []
            }
        },
        observationTypes: {
            type: Array,
            default() {
                return []
            }
        },
        params: {
            type: Object,
            default() {
                return null
            }
        },
    },
    data: function () {
        return {
            tableItems: [],
            selectedObservationTypes: [],
            fields: [
                {
                    key: 'id',
                    label: this.$t('observations.id'),
                    sortable: true
                },
                {
                    key: 'created_time',
                    label: this.$t('observations.created_time'),
                    sortable: true
                },
                {
                    key: 'type_name',
                    label: this.$t('observations.type'),
                    sortable: true
                },
                {
                    key: 'group_name',
                    label: this.$t('observations.group'),
                    sortable: true
                },
                {
                    key: 'length',
                    label: this.$t('observations.length'),
                    sortable: true
                },
                {
                    key: 'road_direction',
                    label: this.$t('observations.direction'),
                    sortable: true
                },
                {
                    key: 'geometry_type',
                    label: this.$t('observations.geometry_type'),
                    sortable: true
                },
                {
                    key: 'road_number',
                    label: this.$t('observations.road_number'),
                    sortable: true
                },
                {
                    key: 'section_number',
                    label: this.$t('observations.road_section_number'),
                    sortable: true
                },
                {
                    key: 'distance_from_sec_start',
                    label: this.$t('observations.distance_from_sec_start'),
                    sortable: true
                },
                {
                    key: 'road_section_length',
                    label: this.$t('observations.road_section_length'),
                    sortable: true
                },
                {
                    key: 'order',
                    label: this.$t('observations.order'),
                    sortable: true
                },
                {
                    key: 'reporter',
                    label: this.$t('observations.reporter'),
                    sortable: true
                },
                {
                    key: 'city',
                    label: this.$t('observations.city'),
                    sortable: true
                },
                {
                    key: 'street_name',
                    label: this.$t('observations.street_name'),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t('observations.status'),
                    sortable: true
                },
                {
                    key: 'info',
                    label: this.$t('work_report.info'),
                    sortable: false
                }
            ],
            sortBy: 'created_time',
            sortDesc: true
        }
    },
    watch: {
        observations() {
            this.initTableItems()
        },
        selectedObservationTypes() {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.tableItems = []
            this.observations.forEach(function (observation) {
                if (observation.can_edit === true) {
                    observation.created_time = this.setTime(observation.created_time)
                    if (observation.closed_time !== undefined) {
                        observation.closed_time = this.setTime(observation.closed_time)
                    }
                    let contract = observation.contract ? observation.contract.name : undefined
                    if (!contract && observation.work_order) {
                        contract = observation.work_order.contract_task_type.contract ? observation.work_order.contract_task_type.contract.name : '-'
                    }
                    if (this.selectedObservationTypes.length === 0 || this.selectedObservationTypes.some(function (selectedType) {
                        return observation.type.id === selectedType.id;
                    })) {
                        this.tableItems.push({
                            id: observation.id,
                            reporter: observation.reporter.first_name + ' ' + observation.reporter.last_name,
                            type_name: observation.type.name,
                            type_id: observation.type.id,
                            group_name: observation.type.observation_group.name,
                            geometry_type: observation.type.geometry_type === 1 ? this.$t('observations.point') : this.$t('observations.line'),
                            length: observation.length ? observation.length : '',
                            created_time: observation.created_time,
                            closed_time: observation.closed_time,
                            city: observation.city ? observation.city.name : '',
                            street_name: observation.street_name ? observation.street_name : '',
                            road_number: observation.road_number ? observation.road_number : '',
                            section_number: observation.section_number ? observation.section_number : '',
                            distance_from_sec_start: observation.distance_from_sec_start ? observation.distance_from_sec_start : '',
                            road_section_length: observation.road_section_length ? observation.road_section_length : '',
                            contract: contract,
                            order: observation.work_order ? observation.work_order.task_type.name : '',
                            status: (observation.closed_time ? this.$t('observations.closed') : this.$t('observations.open')),
                            observation_group: observation.type.observation_group.name,
                            road_direction: observation.road_direction,
                            road_lane: observation.road_lane,
                            info: observation.info ? observation.info : ''
                        })
                    }
                }
            }, this)
        },
        onRowClicked(item) {
            this.$emit('observationSelected', item)
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.observationReportUrl, this.getCommonReportParams(this.params), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('observations.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        exportRoadReport: function () {
            this.loading = true
            this.restGetDownloadFile(this.observationRoadReportUrl, this.getCommonReportParams(this.params), this.roadReportDownloadSuccess, this.fileDownloadFail)
        },
        roadReportDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('observations.road_report_file_name'))
        }
    }
}
</script>
