import { render, staticRenderFns } from "./VehicleStatistic.vue?vue&type=template&id=0ce1bd06"
import script from "./VehicleStatistic.vue?vue&type=script&lang=js"
export * from "./VehicleStatistic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports