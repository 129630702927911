<template>
    <div>
                <h2 class="pl-1" style="font-size: 1.2em; font-weight: bold">
                    {{ observation.type.name }}
                </h2>
                <b-row v-if="observation.public === true">
                    <span class="hint-text">{{ $t('observations.public') }}</span>
                </b-row>
                <b-row>
                    <div class="col-6 nopads pt-3 pr-3">
                        <div class="nopads detail-map" ref="mapCont">
                            <marker-editor-map
                                v-if="observation"
                                :id="observation.id"
                                :lat="getLatitude(observation)"
                                :lon="getLongitude(observation)"
                                :points="getPoints(observation)"
                                :type="OBSERVATION"
                                :draggable="false"
                                @mounted="mapMounted"
                            />
                        </div>
                        <div v-if="!showAllPhotos && observation.photos.length > 0" class="col-12 nopads report-photos">
                            <div class="col-sm-12 nopads">
                                <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="observation.photos.length > 0" style="height: 200px">
                                    <div v-for="photo in observation.photos" :key="photo.id" class="relative nopads stat-map-container slide-img-container">
                                        <ImageView
                                            ref="image"
                                            :img-size="'lg'"
                                            :url="getPhotoUrlForObservation(observationPhotoUrl, observation.id, photo)"
                                            :contain="true"
                                            @imageLoaded="onImageLoaded()"
                                        />
                                    </div>
                                    <div slot="viewport" class="flicking-pagination"></div>
                                </Flicking>
                            </div>
                        </div>
                        <div v-else-if="observation.photos.length > 0" class="col-12 nopads" style="margin-top: 210px; display: block">
                            <table style="border: none">
                                    <tr v-for="(photo, index) in observation.photos" v-bind:key="index" style="border: none">
                                        <td style="border: none">
                                            <ImageView
                                                :img-size="'sm'"
                                                :url="getPhotoUrlForObservation(observationPhotoUrl, observation.id, photo)"
                                                :contain="true"
                                                :height="'250px'"
                                                @imageLoaded="onImageLoaded()"
                                            />
                                        </td>
                                    </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-6 nopads pl-3">
                        <b-row class="nopads" v-if="observation.info">
                            <div class="col-12 nopads">
                                <span class="item-detail-text" style="font-size: 1em; color: #636364">{{ observation.info ? observation.info : '' }}</span>
                            </div>
                        </b-row>
                        <div class="col-12 nopads">
                            <span class="item-detail-title" style="font-size: .8em">{{ $t('observations.id') }}</span>
                            <span class="item-detail-text">{{ observation.id }}</span>
                        </div>
                        <div class="col-12 nopads">
                            <span class="item-detail-title" style="font-size: .8em">{{ $t('observations.created_time') }}</span>
                            <span class="item-detail-text">{{ toLocalTime(observation.created_time) }}</span>
                        </div>
                            <div class="col-12 nopads">
                                <span class="item-detail-title" style="font-size: .8em">{{ $t('contracts.customer') }}</span>
                                <span
                                    v-if="observation.contract"
                                    class="item-detail-text"
                                >
                                {{ observation.contract.customer.name }}
                            </span>
                                <span
                                    v-else-if="observation.work_order && observation.work_order.contract_task_type"
                                    class="item-detail-text"
                                >
                                    {{ observation.work_order.contract_task_type.contract.customer.name }}
                                </span>
                                <span
                                    v-else
                                    class="item-detail-text"
                                >
                                    -
                                </span>
                            </div>
                            <div class="col-12 nopads">
                                <span class="item-detail-title" style="font-size: .8em">{{ $t('observations.contract') }}</span>
                                <span
                                    v-if="observation.contract"
                                    class="item-detail-text"
                                >
                                    {{ observation.contract.name }}
                                </span>
                                <span
                                    v-else-if="observation.work_order && observation.work_order.contract_task_type"
                                    class="item-detail-text"
                                    >
                                  {{ observation.work_order.contract_task_type.contract.name }}
                                </span>
                                <span
                                    v-else
                                    class="item-detail-text"
                                >
                                    -
                                </span>
                            </div>
                            <div class="col-12 nopads">
                                <span class="item-detail-title" style="font-size: .8em">{{ $t('observations.order') }}</span>
                                <span
                                    v-if="observation.work_order && observation.work_order.task_type"
                                    class="item-detail-text"
                                >
                                    {{ observation.work_order.task_type.name }}
                                </span>
                                <span
                                    v-else
                                    class="item-detail-text"
                                >
                                      -
                                </span>
                            </div>
                            <div class="col-12 nopads">
                                <span class="item-detail-title" style="font-size: .8em">{{ $t('observations.status') }}</span>
                                <span class="item-detail-text">{{
                                        observation.closed_time ? $t('observations.closed') : $t('observations.open')
                                    }}</span>
                            </div>
                            <div class="col-12 nopads">
                                <road-addess-view
                                    :road-number="observation.road_number"
                                    :section-number="observation.section_number"
                                    :distance="observation.distance_from_sec_start"
                                    :street-name="observation.street_name"
                                    :enable-copy="false"
                                />
                                <span v-if="observation.city" class="item-detail-text">
                                    {{ observation.city.name }}
                                </span>
                            </div>
                    </div>
                </b-row>
            <hr/>
     </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import ImageView from '../view/ImageView'
import {restApi} from '../mixins/RestApiMixin'
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from '../mixins/MapMixin'
import {observationHelper} from '../mixins/ObservationMixin'
import {Pagination} from "@egjs/flicking-plugins";
import {Flicking} from "@egjs/vue-flicking";
import RoadAddessView from "@/components/velho/RoadAddressView";

export default {
    name: 'ObservationPrintDetails',
    components: {RoadAddessView, MarkerEditorMap, ImageView, Flicking},
    mixins: [timeUtils, restApi, mapHelper, observationHelper],
    props: {
        observation: {
            type: Object,
            default() {
                return null
            }
        },
        reportCompleted: {
            type: Boolean,
            default() {
                return false
            }
        },
        showAllPhotos: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            mapReady: false,
            imgLoaded: 0,
            plugins: [new Pagination({type: 'bullet'})],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
            },
        }
    },
    watch: {
        reportCompleted(val) {
            if (val && this.mapReady) {
                this.switchMapToImage()
            }
        },
        imgLoaded: function() {
            if (this.imgLoaded === this.observation.photos.length) {
                this.$emit('loadingCompleted')
            }
        }
    },
    created() {
        if (this.observation.photos && this.observation.photos.length > 0) {
            this.$emit('loadingStarted')
        } else if (!this.observation.photos || this.observation.photos.length < 1) {
            this.$emit('loadingCompleted')
        }
    },
    methods: {
        getPhotoUrlForObservation: function (baseUrl, observationId, photo) {
            return baseUrl + '/' + observationId + '/' + photo.id
        },
        mapMounted(map) {
            this.map = map
            this.mapReady = true
            if (this.reportCompleted) {
                // No photos in report, give time for map to load
                setTimeout(() => this.switchMapToImage(), 3000 )
            }
            this.$nextTick(() => this.map.hideMapControls())
        },
        switchMapToImage() {
                this.$emit('readyToPrint')
        },
        onImageLoaded() {
            this.imgLoaded++
        },
    }
}
</script>

<style scoped>

.detail-map, .report-photos {
    display: block;
    height: 200px;
    width: 350px;
    overflow: hidden;
}

.detail-map {
    position: absolute;
    border-radius: 1.2em;
    border: 1px solid #BCBCBC;
}

.report-photos {
    margin-top: 210px;
}

</style>

