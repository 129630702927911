<template>
    <base-modal :min-width="true" @cancel="$emit('close')" style="z-index: 50;">
        <div class="col-12">
            <h3 v-if="!roadSign">{{ $t('road_sign.add_new') }}</h3>
            <h3 v-else>{{ $t('road_sign.edit') }}</h3>
        </div>
        <div v-if="roadSign && roadSign.location_deviation" class="col-12 nopads pl-3">
            <location-deviation :location-deviation="roadSign.location_deviation"/>
        </div>
        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12 nopads">
            <b-form class="col-sm-12 editor-form">
                <div v-if="roadSign && !roadSign.external_system_id" class="col-sm-12 carousel-container">
                    <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="roadSign && roadSign.photos && roadSign.photos.length > 0">
                        <div v-for="photo in roadSign.photos" :key="photo.id" class="pointer p-1">
                            <ImageView
                                ref="image"
                                :url="getPhotoUrl(roadSignPhotoUrl, photo)"
                                @click="openImage"
                            />
                        </div>
                        <span slot="viewport" class="flicking-arrow-prev"></span>
                        <span slot="viewport" class="flicking-arrow-next"></span>
                    </Flicking>
                </div>
                <div class="col-sm-12 form-data">
                    <div>
                        <b-row class="nopads">
                            <div v-if="isExternalRoadSign" class="col-sm-6 nopads pl-1 pr-1">
                                <span class="span-title">{{ $t('common.id') }}</span>
                                <span class="item-detail-text">{{ roadSign.external_system_id }}</span>
                            </div>
                            <div v-else class="col-sm-6 nopads pl-1 pr-1">
                                <span class="span-title">{{ $t('common.id') }}</span>
                                <span class="item-detail-text">{{ roadSign && roadSign.id }}</span>
                            </div>
                            <div v-if="roadSign" class="col-sm-6 nopads pl-1 pr-1">
                                <road-addess-view
                                    :road-number="roadSign.road_num"
                                    :section-number="roadSign.sec_num"
                                    :distance="roadSign.distance"
                                    :street-name="roadSign.road_name"/>
                            </div>
                        </b-row>
                        <span class="span-title">{{ $t('road_sign.sign_type') }}</span>
                        <b-form-group
                            label-for="value"
                        >
                            <b-form-select
                                v-model="type"
                                size="sm"
                                :disabled="isExternalRoadSign"
                            >
                                <template slot="first">
                                    <option
                                        :value="null"
                                        disabled
                                    >
                                        {{ $t('road_sign.select_sign_type') }}
                                    </option>
                                </template>
                                <option
                                    v-for="item in getRoadSignTypes()"
                                    :key="item.value"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>

                        <span class="span-title">{{ $t('road_sign.road_sign') }}</span>
                        <multiselect
                            v-model="sign"
                            :placeholder="$t('road_sign.select_sign')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            :multiple="false"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="signs"
                            :disabled="isExternalRoadSign"
                            >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <b-row class="nopads">
                                    <b-col cols="auto" class="nopads">
                                        <img class="option__image"
                                             :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="25px"
                                             alt="">
                                    </b-col>
                                    <b-col style="padding-top: 2px;">
                                        <span class="option__title" style="font-weight: 400">{{
                                                props.option.name
                                            }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <template slot="option"
                                      slot-scope="props">
                                <b-row class="nopads">
                                    <b-col cols="auto" class="nopads">
                                        <img class="option__image"
                                             :src="require(`@/assets/roadsign/${ props.option.icon}`)" height="35px"
                                             alt="">
                                    </b-col>
                                    <b-col class="pt-2">
                                        <span class="option__title pt-2">{{ props.option.name }}</span>
                                    </b-col>
                                </b-row>
                            </template>

                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>

                        <b-row class="nopads">
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.sign_value') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-input
                                        id="value"
                                        v-model="value"
                                        type="number"
                                        :placeholder="$t('road_sign.value_hint')"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <span class="span-title">{{ $t('road_sign.road_direction') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-input
                                        id="value"
                                        max="3"
                                        min="1"
                                        v-model="roadDirection"
                                        type="number"
                                        size="sm"
                                        :disabled="isExternalRoadSign"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.sign_lane') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-input
                                        id="value"
                                        min="1"
                                        v-model="lane"
                                        type="number"
                                        size="sm"
                                        :disabled="isExternalRoadSign"
                                        />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="nopads">
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.installation') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-select
                                        v-model="installation"
                                        size="sm"
                                        :disabled="isExternalRoadSign"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                {{ $t('road_sign.select_installation') }}
                                            </option>
                                        </template>
                                        <option
                                            v-for="item in isExternalRoadSign ? getVelhoInstallations() : getInstallations()"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <span class="span-title">{{ $t('road_sign.side') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-select
                                        v-model="side"
                                        size="sm"
                                        :disabled="isExternalRoadSign"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                {{ $t('road_sign.select_side') }}
                                            </option>
                                        </template>
                                        <option
                                            v-for="item in getSides()"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.material') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-select
                                        v-model="material"
                                        size="sm"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                {{ $t('road_sign.select_material') }}
                                            </option>
                                        </template>
                                        <option
                                            v-for="item in getMaterials()"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="nopads">
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.sign_direction') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-input
                                        id="value"
                                        min="0"
                                        max="359"
                                        v-model="signDirection"
                                        type="number"
                                        :placeholder="$t('road_sign.direction_hint')"
                                        size="sm"
                                        :disabled="isExternalRoadSign"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <span class="span-title">{{ $t('road_sign.condition') }}</span>
                                <b-form-group
                                    label-for="value"
                                >
                                    <b-form-select
                                        v-model="condition"
                                        size="sm"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                {{ $t('road_sign.select_condition') }}
                                            </option>
                                        </template>
                                        <option
                                            v-for="item in getConditions()"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4" class="nopads" v-if="this.isExternalRoadSign">
                                <span class="span-title">{{ $t('road_sign.order_number') }}</span>
                                <b-form-group
                                    label-for="orderNumber"
                                >
                                    <b-form-input
                                        id="orderNumber"
                                        min="0"
                                        v-model="orderNumber"
                                        type="number"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="nopads" v-if="this.isExternalRoadSign">
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.sign_width') }}</span>
                                <b-form-group
                                    label-for="signWidth"
                                >
                                    <b-form-input
                                        id="signWidth"
                                        min="0"
                                        v-model="width"
                                        type="number"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <span class="span-title">{{ $t('road_sign.sign_height') }}</span>
                                <b-form-group
                                    label-for="signHeight"
                                >
                                    <b-form-input
                                        id="signHeight"
                                        min="0"
                                        v-model="height"
                                        type="number"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="4" class="nopads">
                                <span class="span-title">{{ $t('road_sign.sign_color') }}</span>
                                <b-form-group
                                    label-for="signColor"
                                >
                                    <b-form-select
                                        id="signColor"
                                        v-model="signColor"
                                        size="sm"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                                disabled
                                            >
                                                {{ $t('road_sign.select_sign_color') }}
                                            </option>
                                        </template>
                                        <option
                                            v-for="item in getVelhoSignColors()"
                                            :key="item.value"
                                            :value="item.value"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!--    photo editor-->
                        <div v-if="roadSign && !roadSign.external_system_id" class="pb-3">
                            <span class="span-title">{{ $t('vehicle_editor.add_photo') }}</span>
                            <div
                                v-for="(path, counter) in photos"
                                :key="counter"
                                class="col-sm-12 nopads"
                            >
                                <b-form-group
                                    class="title"
                                    label-for="counter"
                                    v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                                >
                                    <b-form-file
                                        v-model="photos[counter]"
                                        accept="image/jpeg, image/jpg, image/png"
                                        :placeholder="$t('equipment.photo_placeholder')"
                                    />
                                </b-form-group>
                            </div>
                        </div>

                        <span class="span-title">{{ $t('road_sign.info') }}</span>
                        <b-form-textarea
                            id="info"
                            v-model="info"
                            :placeholder="$t('road_sign.info')"
                            :rows="4"
                            :max-rows="8"
                        />

                        <span v-if="roadSign && !roadSign.external_system_id" class="span-title" style="text-transform: uppercase; font-size: .9em">{{
                                $t('road_sign.contract_info')
                            }}</span>
                        <open-contract-filter
                            v-if="roadSign && !roadSign.external_system_id"
                            :to="toDate"
                            :from="fromDate"
                            :contract="contract"
                            :show-company="false"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                            style="margin-left: -2px; margin-right: -3px;"/>
                        <b-row class="nopads" v-if="isExternalRoadSign">
                            <div class="col-sm-12 nopads mt-2">
                                <velho-equipment-damages :items="roadSign.equipment_damages"/>
                            </div>
                        </b-row>
                        <equipment-actions v-if="roadSign && roadSign.external_system_id" ref="actions" v-model="actions" />
                        <linked-equipment
                            v-if="roadSign && roadSign.linked_equipment.length > 0"
                            :linked-equipments="roadSign.linked_equipment"
                        />
                    </div>

                    <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                        <b-button
                            v-if="roadSign && !roadSign.external_system_id"
                            variant="info"
                            class="result-button"
                            @click.stop="openDrawGeometryWizard"
                        >
                            {{ $t('road_sign.change_location') }}
                        </b-button>
                        <b-button
                            variant="danger"
                            :disabled="loading || this.roadSign===null"
                            class="result-button"
                            @click.stop="$refs.removeSignModal.show()"
                        >
                            {{ $t('road_sign.remove') }}
                        </b-button>
                        <b-button
                            variant="secondary"
                            class="result-button"
                            @click.stop="$emit('close')"
                        >
                            {{ $t('common.cancel') }}
                        </b-button>
                        <b-button
                            variant="success"
                            class="result-button"
                            :disabled="loading||this.sign===null"
                            @click.stop="handleSave"
                        >
                            {{ $t('common.save') }}
                        </b-button>
                    </div>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
        <draw-geometry-wizard
            v-if="showDrawGeometryWizard !== false"
            :savedGeometry="signCoordinates"
            :observationMode="true"
            @close="closeDrawGeometryWizard"
            @addDrawnGeometry="addGeometryToRoadSign"
        />
        <b-modal
            id="removeSignModal"
            ref="removeSignModal"
            :hide-header=true
            :hide-footer=true
            style="z-index: 100">
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ $t('road_sign.remove') }}</span>
                <span>{{ $t('road_sign.remove_confirmation') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="$refs.removeSignModal.hide()">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="removeSign">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="replaceSignModal"
            ref="replaceSignModal"
            :hide-header=true
            :hide-footer=true
            style="z-index: 100">
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ $t('equipments.replace_title') }}</span>
                <span>{{ $t('equipments.replace_descr') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="$refs.replaceSignModal.hide()">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="$refs.replaceSignModal.hide(); submit(true)">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :largeImages="fullScreenPhotoData"
                :panelIndex="panelIndex"
                @close="closePhoto"/>
        </transition>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import {conversionModelHelper, equipmentActions} from "@/components/mixins/ConversionMixin";
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ImageView from "@/components/view/ImageView";
import FullscreenImage from "@/components/view/FullscreenImage";
import DrawGeometryWizard from "@/components/shapeimport/DrawGeometryWizard";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";
import {Arrow} from "@egjs/flicking-plugins";
import {Flicking} from "@egjs/vue-flicking";
import RoadAddessView from "@/components/velho/RoadAddressView";

export default {
    name: 'road-sign-wizard',
    components: {
        RoadAddessView,
        LocationDeviation, EquipmentActions, FullscreenImage, OpenContractFilter, BaseModal, Flicking, ImageView, DrawGeometryWizard, LinkedEquipment, VelhoEquipmentDamages
    },
    mixins: [restApi, roadSignHelper, timeUtils, attachmentHelper, conversionModelHelper, velhoHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        coordinate: {
            type: Object,
            default: null
        },
        roadSign: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            contract: null,
            type: null,
            sign: null,
            value: null,
            orderNumber: null,
            width: null,
            height: null,
            signColor: null,
            lane: 1,
            roadDirection: 1,
            showDrawGeometryWizard: false,
            signCoordinates: null,
            removeDate: null,
            signs: [],
            actions: [],
            installation: null,
            signDirection: null,
            side: null,
            material: null,
            condition: null,
            info: "",
            fullScreenPhotoData: null,
            photos: new Array(5),
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
                panelsPerView: 3,
            },
            panelIndex: 0
        }
    },
    computed: {
        fromDate() {
            if (this.roadSign) {
                return this.roadSign.created_at
            }
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        },
        isExternalRoadSign() {
            return this.roadSign && !!this.roadSign.external_system_id
        }
    },
    watch: {
        type: function (value) {
            if (value === null) {
                this.sign = null
                this.signs = []
            } else {
                this.signs = this.getSigns(this.type)
            }
        }
    },
    mounted() {
        if (this.roadSign) {
            this.type = this.getSignType(this.roadSign.sign)
            this.$nextTick(() => this.sign = this.signs.find(item => item.value === this.roadSign.sign))
            this.value = this.roadSign.value
            this.lane = this.roadSign.lane
            this.roadDirection = this.roadSign.direction
            this.contract = this.roadSign.contract
            this.removeDate = this.roadSign.removed_at
            this.signCoordinates = {lat: this.roadSign.position.y, lng: this.roadSign.position.x}
            this.info = this.roadSign.info
            this.signColor = this.roadSign.sign_color ? this.roadSign.sign_color : null
            this.orderNumber = this.roadSign.order_number
            this.width = this.roadSign.sign_width
            this.height = this.roadSign.sign_height
            if (this.roadSign.installation) {
                this.installation = this.roadSign.installation
            }
            if (this.roadSign.sign_direction) {
                this.signDirection = this.roadSign.sign_direction
            }
            if (this.roadSign.side) {
                this.side = this.roadSign.side
            }
            if (this.roadSign.material) {
                this.material = this.roadSign.material
            }
            if (this.roadSign.sign_condition) {
                this.condition = this.roadSign.sign_condition
            }
            if (this.roadSign.actions) {
                this.actions = this.roadSign.actions
            }
        } else {
            this.signCoordinates = this.coordinate
            if(this.selectedContract && Object.keys(this.selectedContract).length !== 0) {
                this.contract = this.selectedContract
            }
        }
    },
    methods: {
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        handleSave: function () {
            // Check that the sign is Velho sign
            if(this.roadSign && this.roadSign.external_system_id) {
                if(this.actions.includes(equipmentActions.REPLACE) || this.roadSign.linked_equipment.some(x => x.hasOwnProperty("actions") && !!x.actions.find(y => y.value === equipmentActions.REPLACE))) {
                this.$refs.replaceSignModal.show()
                } else {
                    this.submit(false)
                }
            } else {
                // The sign is Routa own sign or new sign
                this.submit(false)
            }
        },
        submit: function (replace = false) {
            this.loading = true
            let params = {}
            if (this.roadSign && this.roadSign.external_system_id && this.selectedContract) {
                params.contract = {
                    'id': this.selectedContract.id,
                    'external_contract_id': this.selectedContract.external_contract ? this.selectedContract.external_contract.external_id : null
                }
            } else if (this.roadSign && !this.roadSign.external_system_id && this.contract) {
                params.contract = {
                    'id': this.contract.id,
                }
            }
            params.direction = this.roadDirection
            params.lane = this.lane
            params.value = this.value
            if(this.sign && this.sign.value) {
                params.sign = this.sign.value
            }
            params.position = {
                lat: this.signCoordinates.lat,
                lon: this.signCoordinates.lng
            }
            if (this.roadSign && this.roadSign.id && this.roadSign.id > 0) {
                params.id = this.roadSign.id
            } else if(this.roadSign && this.roadSign.external_system_id) {
                params.equipment_damages = this.roadSign.equipment_damages ? this.roadSign.equipment_damages : []
                params.external_system_id = this.roadSign.external_system_id
            }
            if(this.roadSign && this.roadSign.linked_equipment.length > 0) {
                params.linked_equipment = this.roadSign.linked_equipment
            }
            params.removed_at = this.removeDate
            params.installation = this.installation
            params.side = this.side
            params.sign_condition = this.condition
            params.material = this.material
            params.info = this.info
            params.sign_direction = this.signDirection
            params.actions = this.actions
            params.order_number = this.orderNumber
            params.sign_width = this.width
            params.sign_height = this.height
            params.sign_color = this.signColor
            if(replace) {
                this.restAdd(this.extEquipmentsReplaceUrl, [params], this.uploadCompleted, this.fail)
            } else {
                if ((params.id && params.id > 0) || (params.external_system_id)) {
                    this.restUpdate(this.roadSign.external_system ? this.roadSignExtUrl : this.roadSignUrl, params, this.success, this.fail)
                } else {
                    this.restAdd(this.roadSignUrl, params, this.success, this.fail)
                }
            }
        },
        openDrawGeometryWizard() {
            this.showDrawGeometryWizard = true
        },
        closeDrawGeometryWizard() {
            this.showDrawGeometryWizard = false
        },
        addGeometryToRoadSign(geometry) {
            if(geometry && geometry.point && geometry.point.lat && geometry.point.lon) {
                this.signCoordinates = {lat: geometry.point.lat, lng: geometry.point.lon}
            }
            this.closeDrawGeometryWizard()
        },
        success: function (response) {
            let photos = this.getValidAttachments(this.photos)
            if (photos.length > 0) {
                this.uploadPhoto(photos, response.id)
            } else {
                this.uploadCompleted()
            }
        },
        uploadPhoto: function (photos, id) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.roadSignPhotoUrl + id, {}, formData, this.uploadCompleted, this.fail)
            }
        },
        uploadCompleted: function () {
            this.loading = false
            this.showAlert(this.$t('road_sign.added'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        },
        removeSign() {
            if(this.roadSign.external_system_id) {
                this.removalConfirmed(null, this.roadSign, this.selectedContract)
            } else {
                let d = new Date()
                this.removeDate = d.toISOString()
                this.submit()
            }
        },
        getPhotoUrl: function (baseUrl, photo) {
            return baseUrl + 'url/' + this.roadSign.id + '/' + photo.id
        },
        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index

        },
        closePhoto() {
            this.fullScreenPhotoData = undefined
        }
    }
}
</script>
<style>
 .modal-container {
    min-width: 70%;
}
@import "../../styles/modal.css";
</style>
