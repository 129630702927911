import Vue from 'vue'
import axios from 'axios'
import {restApi} from '../../components/mixins/RestApiMixin'

const Mixin = Vue.extend({
    mixins: [restApi],
})
const rest = new Mixin()

export const namespaced = true

export const state = {
    isLoading: false,
    selectedContractId: null,
    selectedContract: {},
    selectedContractIds: [],
    selectedContracts: [],
    selectedTaskType: null,
    selectedOrder: null,
    showContractGeometry: false,
    showContractGeometryLabels: false,
    showTrafficControl: false,
    error: '',
    cancelRequest: null,
}

export const actions = {
    selectContracts({ commit, dispatch }, contracts) {
        if (!contracts) {
            commit('SELECT_CONTRACTS', null)
            return
        }
        dispatch('selectTaskType', null)
        commit('SELECT_CONTRACTS', contracts)
    },
    setShowTrafficControl({ commit }, showTrafficControl) {
        commit('SET_SHOW_TRAFFIC_CONTROL', showTrafficControl)
    },
    setShowContractGeometry({ commit }, showContractGeometry) {
        commit('SET_SHOW_CONTRACT_GEOMETRY', showContractGeometry)
    },
    setShowContractGeometryLabels({ commit }, showContractGeometryLabels) {
        commit('SET_SHOW_CONTRACT_GEOMETRY_LABEL', showContractGeometryLabels)
    },
    selectTaskType({ commit }, taskType) {
        if (!taskType) {
            commit('SELECT_TASKTYPE', null)
            return
        }
        commit('SELECT_TASKTYPE', taskType)
    },
    selectOrder({ commit }, order) {
        if (!order) {
            commit('SELECT_ORDER', null)
            return
        }
        commit('SELECT_ORDER', order)
    },
    async getContracts({ commit, rootState, dispatch }, params) {
        dispatch('cancelRequest')

        commit('SET_ERROR', '')
        commit('SET_LOADING', true)

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        commit('CANCEL_REQUEST', source.cancel)

        try {
            const response = await axios.get(rest.contractUrl, {
                cancelToken: source.token,
                headers: {
                    Authorization: rootState.token
                },
                params: {
                    ...params
                }
            })
            return response.data;
        } catch (err) {
            if (axios.isCancel(err)) {
                return
            }
            commit('SET_ERROR', 'Could not get contracts')
        } finally {
            commit('SET_LOADING', false)
            commit('CANCEL_REQUEST', null)
        }
    },
    cancelRequest() {
        if (state.cancelRequest) {
            state.cancelRequest()
        }
    }
}

export const mutations = {
    SELECT_CONTRACTS(state, contracts) {
        // Set selectedContractIds as an array
        state.selectedContractIds = contracts && contracts.length > 0 ? contracts.map(item => item.id) : [];
        // Set selectedContracts as an array of objects
        state.selectedContracts = contracts && contracts.length > 0 ? contracts : [];
        if (!contracts || contracts.length < 1) {
            state.showContractGeometry = false
            state.showContractGeometryLabels = false
        }
    },
    SELECT_TASKTYPE(state, taskType) {
        state.selectedTaskType = taskType ? taskType : null
    },
    SELECT_ORDER(state, order) {
        state.selectedOrder = order ? order : null
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
        state.error = error
    },
    SET_SHOW_CONTRACT_GEOMETRY(state, showContractGeometry) {
        state.showContractGeometry = showContractGeometry
    },
    SET_SHOW_CONTRACT_GEOMETRY_LABEL(state, showContractGeometryLabels) {
        state.showContractGeometryLabels = showContractGeometryLabels
    },
    SET_SHOW_TRAFFIC_CONTROL(state, showTrafficControl) {
        state.showTrafficControl = showTrafficControl
    },
    CANCEL_REQUEST(state, func) {
        state.cancelRequest = func
    }
}
