<template>
    <div class="col-12 nopads">
        <b-row class="col-sm-12 nopads">
            <div class="col-sm-6 nopads pt-2 pl-3">
                <b-row>
                    <b-col>
                        <b-form-checkbox id="invoicingToggle" v-model="showInvoicingFields" switch size="sm" class="mr-4">
                            {{ $t('daily_summary.show_invoicing_functions')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox id="detailsToggle" v-model="showDetails" switch size="sm" class="mr-4">
                            {{ $t('trip_list.details')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <div class="col-sm-6 nopads text-right">
                <b-button
                    v-if="showInvoicingFields"
                    variant="primary"
                    size="sm"
                    class="result-button dont-print"
                    :disabled="Object.keys(this.changedInvoiceableSums).length === 0 && invoiceStatesToBeChanged.length < 1 && !loading"
                    @click="confirmInvoiced = true"
                >
                    {{ $t('daily_summary.set_invoiced_state') }}
                </b-button>
                <b-button
                    variant="outline-success"
                    size="sm"
                    class="result-button dont-print"
                    :disabled="summaries.length < 1 && !loading"
                    @click="exportExcel"
                >
                    {{ $t('trip.download_excel') }}
                </b-button>
            </div>
        </b-row>
        <div class="col-sm-12 list-container">
                <b-table
                    show-empty
                    small
                    selectable
                    :select-mode="selectMode"
                    selected-variant="success"
                    striped hover
                    :items="tableItems"
                    :fields="fields"
                    @row-clicked="showTrips"
                >
                    <template v-slot:cell(date)="row">
                        {{ getShortDateString(row.value) }}
                    </template>
                    <template v-slot:cell(work_distance)="row">
                        <span v-bind:class="{ dangerText: highlightDistance(row.item.work_distance, row.item.covered_road_distance) }"> {{ row.value }} </span>
                    </template>
                    <template v-slot:cell(covered_road_distance)="row">
                        <span v-bind:class="{ dangerText: highlightDistance(row.item.work_distance, row.item.covered_road_distance) }">
                            {{ row.value }}
                        </span>
                        <font-awesome-icon
                            v-if="row.item.unhandled_coverage_areas"
                            icon="exclamation-circle"
                            class="defect"
                        />
                    </template>
                    <template v-slot:cell(contract)="row">
                        <span @click.stop="showContract(row.item)" class="cell-link">{{ row.item.contract}}</span>
                    </template>
                    <template v-slot:cell(cost)="row">
                        {{ isNaN(row.value) ? row.value : row.value.toFixed(2) }}
                    </template>
                    <template v-slot:cell(order)="row">
                        <span @click.stop="showOrder(row.item)" class="cell-link">{{ row.item.order }}</span>
                    </template>
                    <template v-slot:cell(square_meters)="row">
                        {{ row.item.square_meters ? Math.round(row.item.square_meters) : '' }}
                    </template>
                    <template v-slot:cell(invoice_amount)="row">
                        <!-- €/t/km and €/t/m needs to be shown as total tons -->
                        <div v-if="isDeliveryListBased(row.item.pricing_model)" class="nopads">
                            <span v-if="row.item.delivery_tons && row.item.delivery_tons > 0"
                                @click.stop="showDeliveries(row.item)" class="cell-link">
                            {{ row.item.delivery_tons.toFixed(2) }} t
                            </span>
                            <span v-if="row.item.delivery_cubics && row.item.delivery_cubics > 0"
                                  @click.stop="showDeliveries(row.item)" class="cell-link">
                            {{ row.item.delivery_cubics.toFixed(2) }} m3
                        </span>
                        </div>
                        <div v-else class="nopads">
                            {{ row.item.invoice_amount ? row.item.invoice_amount.toFixed(2) : '-' }}
                        </div>
                    </template>
                    <template v-slot:cell(unit_price)="row">
                        {{ row.item.unit_price ? (row.item.unit_price/100).toFixed(3) : '-' }}
                    </template>
                    <template v-slot:cell(invoice_state)="row">
                        <b-form-select
                            :id="row.item.id + 'invoice_state'"
                            size="sm"
                            style="min-width: 10em"
                            v-model="invoiceSelectStates[row.item.id]"
                            :disabled="row.item.invoice_state === invoiceStates().INVOICED"
                            @change="changeRowInvoiceState(row.item.id, invoiceSelectStates[row.item.id], row.item.invoice_state)"
                            @click.stop=''
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('daily_summary.select_invoice_state') }}
                                </option>
                            </template>
                            <option
                                v-for="option in invoiceStateOptions"
                                :key="option.value"
                                :value="option.value"
                                :disabled="isOptionDisabled(option, row.item.invoice_state, row)"
                            >
                                {{ option.text }}
                            </option>
                        </b-form-select>
                    </template>
                    <template v-slot:cell(invoiceable_sum)="row">
                        <span :id="row.item.id + 'invoiceable_sum'" :class="row.item.invoice_state !== invoiceStates().INVOICED ? 'cell-link' : ''" @click.stop="row.item.invoice_state !== invoiceStates().INVOICED ? selectInvoiceableSum(row.item) : null">
                            {{ parseFloat(row.item.invoiceable_sum).toFixed(2) }}
                        </span>
                    </template>
                    <!-- Delivery tons -->
                    <template v-slot:cell(delivery_tons)="row">
                        <span v-if="row.item.delivery_tons && row.item.delivery_tons > 0"
                              @click.stop="showDeliveries(row.item)" class="cell-link">
                            {{ row.item.delivery_tons.toFixed(2) }} t
                        </span>
                        <span v-if="row.item.delivery_cubics && row.item.delivery_cubics > 0"
                              @click.stop="showDeliveries(row.item)" class="cell-link">
                            {{ row.item.delivery_cubics.toFixed(2) }} m3
                        </span>
                    </template>
                    <!-- Trip notes -->
                    <template v-slot:cell(tripnote)="row">
                        <span @click.stop="" v-if="row.item.tripnotes && row.item.tripnotes.length > 0">
                            <b-tooltip :target="'notes'+row.item.id" triggers="hover" placement="left">
                                <span
                                    v-for="tripNote in row.item.tripnotes"
                                    v-bind:key="tripNote.id">
                                    <span class="tooltip-title">{{ toLocalTime(tripNote.start_time) }}-{{ toLocalTime(tripNote.end_time) }}</span>
                                    {{ tripNote.note }}
                                </span>
                            </b-tooltip>
                            <font-awesome-icon
                                :id="'notes'+row.item.id"
                                icon="comment"
                                class="note"
                            />
                        </span>
                    </template>

                </b-table>
        </div>

        <b-modal
            ref="invoiceableSumModal"
            v-model="showChangeInvoiceableSumModal"
            :title="$t('daily_summary.confirm_invoiceable_sum')"
            :hide-header-close="true"
            :hide-footer="true"
            @ok="changeInvoiceableSum(selectedSummaryRow)"
            @close="showChangeInvoiceableSumModal = false"
        >
            <b-row class="nopads pt-3">
                <b-form-group
                    class="col-12"
                    label-for="info"
                >
                    <span>{{ $t('daily_summary.invoiceable_sum')}}</span>
                    <b-form-input
                        id="invoiceableSum"
                        v-model="selectedSummaryRow.invoiceable_sum"
                        type="number"
                        size="sm"
                    />
                </b-form-group>
            </b-row>
            <b-row class="nopads">
                <b-form-group class="col-12">
                    <span>{{ $t('daily_summary.invoiceable_sum_explanation')}}</span>
                    <b-form-textarea
                        id="invoiceExplanation"
                        v-model="selectedSummaryRow.invoice_explanation"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
            </b-row>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="showChangeInvoiceableSumModal = false">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="changeInvoiceableSum(selectedSummaryRow)">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>

        <b-modal
            ref="companyModal"
            v-model="confirmInvoiced"
            :title="$t('daily_summary.confirm_invoice_states')"
            :hide-header-close="true"
            :hide-footer="true"
            @ok="submitInvoiceChanges"
            @close="confirmInvoiced = false"
        >
            <span>{{ $t('daily_summary.confirm_invoiced_description')}}</span>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="confirmInvoiced = false">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="submitInvoiceChanges">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {invoiceStates} from "@/components/invoice/InvoiceMixin";
import {vehicleHelper} from "@/components/mixins/VehicleMixin";
import {EventBus} from "@/event-bus";
import {restApi} from "@/components/mixins/RestApiMixin";
import {downloadHelper} from "@/components/mixins/DownloadMixin";
import {priceHelper} from "@/components/mixins/PricingMixin";

export default {
    name: "contract-trip-summary-list",
    mixins: [restApi, timeUtils, vehicleHelper, downloadHelper, invoiceStates, priceHelper],
    props: {
        summaries: {
            type: Array,
            default() {
                return []
            }
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
        params: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            tableItems: [],
            changedInvoiceableSums: {},
            selectedSummaryRow: {},
            showChangeInvoiceableSumModal: false,
            invoiceStatesToBeChanged: [],
            invoiceSelectStates: {},
            confirmInvoiced: false,
            loading: false,
            showInvoicingFields: false,
            showDetails: false,
            selectMode: 'single',
            fields: [],
            defaultFields: [
                {
                    date: {
                        label: this.$t('daily_summary.date'),
                        sortable: true
                    }
                },
                {
                    company: {
                        label: this.$t('daily_summary.company'),
                        sortable: true
                    }
                },
                {
                    driver: {
                        label: this.$t('trip_list.driver'),
                        sortable: true
                    }
                },
                {
                    vehicle: {
                        label: this.$t('trip_list.vehicle'),
                        sortable: true
                    }
                },

                {
                    customer: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    order: {
                        label: this.$t('vehicle_position.order'),
                        sortable: true
                    }
                },
                {
                    invoice_amount: {
                        label: this.$t('trip_list.amount'),
                        sortable: true
                    }
                },
                {
                    invoice_unit: {
                        label: this.$t('trip_list.unit'),
                        sortable: true
                    }
                },
                {
                    unit_price: {
                        label: this.$t('trip_list.unit_price'),
                        sortable: false
                    }
                },
                {
                    cost: {
                        label: this.$t('daily_summary.cost'),
                        sortable: true
                    }
                },
                {
                    tripnote: {
                        label: this.$t('daily_summary.trip_notes'),
                        sortable: false
                    }
                }
            ],
            detailedFields: [
                {
                    date: {
                        label: this.$t('daily_summary.date'),
                        sortable: true
                    }
                },
                {
                    company: {
                        label: this.$t('daily_summary.company'),
                        sortable: true
                    }
                },
                {
                    driver: {
                        label: this.$t('trip_list.driver'),
                        sortable: true
                    }
                },
                {
                    vehicle: {
                        label: this.$t('trip_list.vehicle'),
                        sortable: true
                    }
                },

                {
                    customer: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    order: {
                        label: this.$t('vehicle_position.order'),
                        sortable: true
                    }
                },
                {
                    duration: {
                        label: this.$t('trip_list.duration'),
                        sortable: true
                    }
                },
                {
                    work_duration: {
                        label: this.$t('trip_list.work_duration'),
                        sortable: true
                    }
                },
                {
                    allocation: {
                        label: this.$t('trip_list.vehicle_allocation'),
                        sortable: false
                    }
                },
                {
                    work_distance: {
                        label: this.$t('daily_summary.work_distance'),
                        sortable: true
                    }
                },
                {
                    resettle_distance: {
                        label: this.$t('daily_summary.resettle_distance'),
                        sortable: true
                    }
                },
                {
                    covered_road_distance: {
                        label: this.$t('daily_summary.road_distance'),
                        sortable: true
                    }
                },
                {
                    square_meters: {
                        label: this.$t('areas.square_meters'),
                        sortable: true
                    }
                },
                {
                    avg_speed: {
                        label: this.$t('trip_list.avg_speed'),
                        sortable: true
                    }
                },
                {
                    delivery_tons: {
                        label: this.$t('daily_summary.delivery_tons'),
                        sortable: true
                    }
                },
                /*
                {
                    co2_emissions: {
                        label: this.$t('trip_list.co2_emissions'),
                        sortable: true
                    }
                },*/
                {
                    cost: {
                        label: this.$t('daily_summary.cost'),
                        sortable: true
                    }
                },
                {
                    tripnote: {
                        label: this.$t('daily_summary.trip_notes'),
                        sortable: false
                    }
                }
            ],
            invoicingFields: [
                {
                    invoiceable_sum: {
                        label: this.$t('daily_summary.invoiceable_sum'),
                        sortable: true
                    }
                },
                {
                    invoice_explanation: {
                        label: this.$t('daily_summary.invoice_explanation'),
                        sortable: false
                    }
                },
                {
                    invoice_state: {
                        label: this.$t('daily_summary.invoice_state'),
                        sortable: true
                    }
                },
            ]
        }
    },
    watch: {
        summaries: function () {
            this.getTableItems()
        },
        showInvoicingFields () {
           this.updateTableFields()
        },
        showDetails() {
            this.updateTableFields()
        }
    },
    mounted() {
        this.updateTableFields()
        this.getTableItems()
    },
    methods: {
        updateTableFields() {
            let fields
            if (this.showInvoicingFields) {
                let visibleFields = this.showDetails ? this.detailedFields : this.defaultFields
                fields = [
                    ...visibleFields.slice(0, visibleFields.length-1),
                    ...this.invoicingFields,
                    ...visibleFields.slice(visibleFields.length-1)
                    ]
            } else {
                fields = this.showDetails ? this.detailedFields : this.defaultFields
            }
            this.fields = fields
        },

        invoiceStates() {
            return invoiceStates
        },
        setInvoiceState(invoiceId, invoiceStateValue) {
            this.$set(this.invoiceSelectStates, invoiceId, invoiceStateValue);
            return invoiceStateValue;
        },
        isOptionDisabled(option, currentInvoiceState, invoiceRow) {
            if(invoiceRow.item.customer_id !== this.user.company.id && option.canBeRestricted) {
                return true
            }
            return false;
        },
        getTableItems: function () {
            this.tableItems = []
            if (this.summaries.length > 0) {
                this.summaries.forEach(function (item) {
                    this.tableItems.push({
                        id: item.id,
                        date: item.date,
                        company: item.company,
                        driver: item.driver,
                        driver_id: item.driver_id,
                        vehicle: item.vehicle,
                        customer: item.customer,
                        customer_id: item.customer_id,
                        contract: item.contract,
                        contract_id: item.contract_id,
                        contractors: item.contractor,
                        order: item.order ? item.order.task_type.name + ' (' + item.order.id +')' : '-',
                        area: item.area ? item.area : '-',
                        area_id: item.area_id ? item.area_id : undefined,
                        work_distance: parseFloat(item.work_distance < 10 ?
                            item.work_distance.toFixed(2) :
                            item.work_distance.toFixed(1)).toLocaleString(),
                        resettle_distance: parseFloat(item.resettle_distance < 10 ?
                            item.resettle_distance.toFixed(2) :
                            item.resettle_distance.toFixed(1)
                        ).toLocaleString(),
                        covered_road_distance: item.covered_road_distance ? parseFloat(item.covered_road_distance.toFixed(2)).toLocaleString() : '',
                        duration: this.getWorkTime(Math.round(item.duration / 60)),
                        work_duration: this.getWorkTime(Math.round(item.work_duration / 60)),
                        square_meters: item.square_meters,
                        allocation: this.getWorkTime(item.vehicle_allocation_time),
                        delivery_tons: item.delivery_tons,
                        delivery_cubics: item.delivery_cubics,
                        avg_speed: this.getAverageSpeed(item.work_distance + item.resettle_distance, item.duration / 60),
                        co2_emissions: (item.co2_emissions && item.co2_emissions > 0) ? Math.round(item.co2_emissions * (item.work_distance + item.resettle_distance))/1000.0 : '-',
                        cost: item.cost ? item.cost : '-',
                        invoiced: item.invoiced,
                        invoiceable_sum: item.invoiceable_sum ? item.invoiceable_sum : item.cost,
                        invoice_explanation: item.invoice_explanation,
                        invoice_state: this.setInvoiceState(item.id, item.invoice_state),
                        tripnotes: item.tripnotes,
                        unhandled_coverage_areas: item.unhandled_coverage_areas,
                        invoice_unit: item.pricing_model ? this.pricingModelToText(item.pricing_model) : '-',
                        invoice_amount: item.invoice_amount,
                        unit_price: item.unit_price,
                        pricing_model: item.pricing_model
                    })
                }, this)
            }
        },
        isDeliveryListBased(pricingModel) {
            return pricingModel &&
                (pricingModel.unit1 === 5 || pricingModel.unit1 === 6)
                && pricingModel.unit2 === 103
        },
        selectInvoiceableSum(item) {
            this.showChangeInvoiceableSumModal = true
            this.selectedSummaryRow = {
                ...item,
                invoiceable_sum: item.invoiceable_sum,
                invoice_explanation: item.invoice_explanation || '' // Use invoice_explanation if available, otherwise use an empty string
            };
        },
        exportExcel: function () {
            this.$emit('loading', true)
            this.restGetDownloadFile(this.contractDailyTripSummaryReportUrl, this.getCommonReportParams(this.params), this.fileDownloadSuccess, this.fileDownloadFail)
        },
        fileDownloadSuccess: function (response) {
            this.$emit('loading', false)
            this.downloadFile(response, this.$t('daily_summary.report_file_name'))
        },
        fileDownloadFail: function () {
            this.$emit('loading', false)
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        highlightDistance: function (workDistance, roadDistance) {
            return roadDistance ? workDistance / roadDistance >= 3 : false;
        },
        showTrips: function (row) {
            if (!this.loading) {
                let selectedItem = this.summaries.find(item => item.id === row.id)
                if (selectedItem){
                    this.$emit('rowSelected', selectedItem)
                }
            }
        },
        showDeliveries: function (row) {
            if (!this.loading) {
                let selectedItem = this.summaries.find(item => item.id === row.id)
                if (selectedItem){
                    this.$emit('deliveryTonsClicked', selectedItem)
                }
            }
        },
        showContract: function (row) {
            if (!this.loading) {
                let selectedItem = this.summaries.find(item => item.id === row.id)
                if (selectedItem){
                    this.$emit('contractClicked', selectedItem)
                }
            }
        },
        showOrder: function (row) {
            if (!this.loading) {
                let selectedItem = this.summaries.find(item => item.id === row.id)
                if (selectedItem){
                    this.$emit('orderClicked', selectedItem)
                }
            }
        },
        changeRowInvoiceState(itemId, updateStateValue, originalStateValue) {
            if(updateStateValue == null) {
                return
            }
            // Check if an object with the same itemId already exists in the array
            const existingIndex = this.invoiceStatesToBeChanged.findIndex(obj => obj.id === itemId);
            if (existingIndex < 0) {
                // If an object with the same itemId doesn't exist, add it
                this.invoiceStatesToBeChanged.push({ id: itemId, updateStateValue });
            } else {
                // if value is changed then update if not then remove from change array
                if (updateStateValue !== originalStateValue) {
                    this.invoiceStatesToBeChanged[existingIndex].stateValue = updateStateValue;
                } else {
                    this.invoiceStatesToBeChanged.splice(existingIndex, 1);
                }
            }
        },
        submitInvoiceChanges() {
            this.confirmInvoiced = false
            this.loading = true
            this.$emit('loading', true)
            this.restUpdate(this.setInvoiceChangesDailyTripSummaryUrl, {stateChanges: this.invoiceStatesToBeChanged, sumChanges: this.changedInvoiceableSums}, this.invoiceChangesSuccess, this.invoiceChangesFail)
        },
        invoiceChangesSuccess() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.$emit('loading', false)
            this.changedInvoiceableSums = {}
            this.invoiceStatesToBeChanged = []
            this.$emit('updateRequest')
        },
        invoiceChangesFail() {
            this.loading = false
            this.$emit('loading', false)
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        changeInvoiceableSum(selectedSummaryRow) {
            this.showChangeInvoiceableSumModal = false
            if (!this.changedInvoiceableSums[selectedSummaryRow.id]) {
                this.changedInvoiceableSums[selectedSummaryRow.id] = {}; // Initialize the object if it doesn't exist
            }
            this.changedInvoiceableSums[selectedSummaryRow.id]['id'] = selectedSummaryRow.id
            this.changedInvoiceableSums[selectedSummaryRow.id]['invoiceable_sum'] = selectedSummaryRow.invoiceable_sum
            this.changedInvoiceableSums[selectedSummaryRow.id]['invoice_explanation'] = selectedSummaryRow.invoice_explanation
            if(this.tableItems.length > 0) {
                this.tableItems.forEach(tableItem => {
                    if(tableItem.id === selectedSummaryRow.id) {
                        tableItem.invoiceable_sum = selectedSummaryRow.invoiceable_sum
                        tableItem.invoice_explanation = selectedSummaryRow.invoice_explanation
                    }
                })
            }
        },
    }
}
</script>

<style>
.dangerText {
    font-weight: bold;
    color: #bb330e;
}
</style>
