<template>
    <div class="nopads">
        <ul class="suggestions-list">
            <li v-for="marker in markers" :key="marker.id" @click.stop="$emit('mapItemSelected', marker)">
                <div v-if="marker.type === TRAFFIC_SIGN || marker.type === EXTERNAL_TRAFFIC_SIGNS" class="nopads">
                    <img :src="require(`@/assets/roadsign/`+ getSignIcon(marker.data.sign))"
                         height="20px"
                         style="margin-right: .5em"
                         alt="">
                    {{ getEquipmentTypeName(marker.type) }} {{ marker.data.sign }}
                </div>
                <div v-else-if="marker.data && marker.data.external_system_id" class="nopads">
                    <img :src="getEquipmentIcon(marker.type)"
                         height="20px"
                         style="margin-right: .5em"
                         alt="">
                    {{ getEquipmentTypeName(marker.type) }}
                </div>
                <div v-else class="nopads pt-1 pl-2">
                    <div v-html="getMarkerDiv(markerStore, marker.type)" style="display: inline-block; margin-right: .5em"/>
                    {{ marker.data && marker.data.name ? marker.data.name : getMarkerTypeName(marker.type) }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";

export default {
    name: "MapItemSelector",
    mixins: [mapHelper, conversionModelHelper, roadSignHelper],
    props: {
        markerStore: {
            type: Object,
            required: true
        },
        markers: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function(){
        return{
            options: [],
        }
    },
    mounted() {
        this.options = [
            { value: this.OBSERVATION, text: this.$t('menu.observations') },
            { value: this.WORK_ASSIGNMENT, text: this.$t('map_tooltips.work_assignments') },
            { value: this.TRAFFIC_SIGN, text: this.$t('map_tooltips.traffic_signs') },
            { value: this.STREET_LIGHT, text: this.$t('menu.street_lights') },
            { value: this.POWER_CENTER, text: this.$t('power_stations.station') },
            { value: this.CABLE, text: this.$t('cables.cable') },
            { value: this.SEWERS, text: this.$t('storm_sewers.sewers') },
            { value: this.DRAINS, text: this.$t('storm_sewers.drains') },
            { value: this.SEWERS_AND_DRAINS, text: this.$t('storm_sewers.sewers_and_drains') }
        ]
    }
}
</script>

<style lang="scss" scoped>
ul.suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow: auto;

    li {
        padding: 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: background-color 0.25s ease-out;
        cursor: pointer;

    &:last-child {
         border-bottom: none;
     }
    &:only-child {
         border-top: 1px solid rgba(0, 0, 0, 0.1);
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     }
    &:hover {
         background-color: rgba(0, 0, 0, 0.1);
     }
    }
}
</style>
