export const units = {
    HOUR: '1',
    DAY: '2',
    WEEK: '3',
    MONTH: '4',
    TONS: '5',
    METERS: '6',
    SQUARE_METERS: '8',
    CUBIC_METERS: '10',
    KILOMETERS: '7',
    PCS: '9'
}

export const orderStatus = {
    OPEN: 1,
    IN_PROGRESS: 2,
    PLAN: 3,
    STORED: 4,
    COMPLETED: 10,
    CANCELLED: 20,
    APPROVED: 30,
    INVOICED: 40,
    CLOSED: 50,
    ABORTED: 60
}

export const workManagementHelper = {
    data() {
        return {
            orderStatusArray: [
                orderStatus.OPEN,
                orderStatus.IN_PROGRESS,
                orderStatus.PLAN,
                orderStatus.STORED,
                orderStatus.COMPLETED,
                orderStatus.CANCELLED,
                orderStatus.APPROVED,
                orderStatus.INVOICED,
                orderStatus.CLOSED,
                orderStatus.ABORTED
            ]
        }
    },
    methods: {
        newTaskType: function () {
            return {
                id: -1,
                name: '',
                color: '#00FF21',
                inspections: []
            }
        },

        newCostCenter: function () {
            return {
                id: -1,
                name: ''
            }
        },

        newCompany: function (like) {
            return {
                id: -1,
                name: '',
                business_id: '',
                vat_id: '',
                address: {},
                invoicing_address: {},
                invoicing_net_operator: '',
                invoicing_net_code: '',
                location: like && like.location ? like.location : {x: null, y: null},
                ...like
            }
        },

        newContract: function () {
            return {
                id: -1,
                name: '',
                customer: -1,
                contractor: -1,
                start_date: '',
                end_date: '',
                work_site_key: '',
                contract_number: '',
                task_types: [],
                area_group: {
                    id: null
                },
                certificate_types: [],
                harja_contract_id: '',
                report_tracking_mode: 1,
                prevent_observation_order_link: false,
                is_contract_party: true
            }
        },

        newContractDiary: function () {
            return {
                id: -1,
                events: "",
                deviations: "",
                duty_officer: null,
                customer_contacts_info: "",
                customer_contacts_received: false,
                citizen_contacts_received: false,
                citizen_contacts_info: "",
                authorities_assistance: false,
                authorities_assistance_hours: 0,
                authorities_assistance_info: "",
                contract: null,
                exceptional_weather: false,
                exceptional_weather_info: "",
                exceptional_weather_location: "",
                media_contacts_info: "",
                media_contacts_received: false,
                record_date: null
            }
        },

        createEditorOrder: function (order) {
            const obj = {
                id: -1,
                customer: null,
                contract: null,
                contract_task_type: {
                    id: null,
                    task_type: null
                },
                contractor: null,
                create_time: '',
                start_time: '',
                complete_time: '',
                planned_start: '',
                planned_ready: '',
                resp_person: {
                    id: null
                },
                info: '',
                plan: '',
                realized_amount: 0,
                ordered_amount: 0,
                status: 1,
                unit: '',
                executors: [],
                object_number: 0,
                attachments: [],
                pricing_model: null,
                unit_price: 0,
                price_list: null,
                area_price_list: null,
                external_upload: false,
                generate_external_reports: false,
                external_tasks: []
            }
            if (order != null) {
                obj.id = order.id
                obj.customer = order.contract_task_type.contract.customer.id
                obj.customer_name = order.contract_task_type.contract.customer.name
                obj.contract = order.contract_task_type.contract.id
                obj.contract_name = order.contract_task_type.contract.name
                obj.contract_task_type = {
                    id: order.contract_task_type.id,
                    task_type: {id: order.contract_task_type.task_type.id}
                }
                obj.contract_task_type_name = order.contract_task_type.task_type.name
                obj.contractor = order.contract_task_type.contract.contractor.id
                obj.contractor_name = order.contract_task_type.contract.contractor.name
                obj.create_time = order.create_time
                obj.start_time = order.start_time
                obj.complete_time = order.complete_time ? order.complete_time + 'Z' : order.complete_time // Add 'Z' to show date in local timezone
                obj.planned_start = order.planned_start
                obj.planned_ready = order.planned_ready
                obj.resp_person = {id: order.resp_person != null ? order.resp_person.id : null}
                obj.info = order.info
                obj.plan = order.plan
                obj.realized_amount = order.realized_amount
                obj.ordered_amount = order.ordered_amount
                obj.status = order.status
                obj.unit = order.unit
                obj.executors = order.executors
                obj.object_number = order.object_number
                obj.attachments = order.attachments
                obj.pricing_model = order.pricing_model
                obj.unit_price = order.unit_price / 100.0
                obj.price_list = order.price_list
                obj.area_price_list = order.area_price_list
                obj.external_upload = order.external_upload
                obj.generate_external_reports = order.generate_external_reports
                obj.cost_estimation = order.cost_estimation
                obj.material_reporting = order.material_reporting
                obj.material_report_unit = order.material_report_unit
                obj.material_prefill = order.material_prefill
                obj.external_tasks = order.external_tasks
            }
            return obj
        },

        newWorkTimeMode: function () {
            return {
                id: -1,
                name: null,
                work_type: false,
                absent_type: false,
                company: null
            }
        },

        newMaterialStation: function (lat = 62.24, lon = 25.74) {
            return {
                id: -1,
                name: null,
                location: {y: lat, x: lon},
                station_materials: [],
                station_scales: []
            }
        },

        newWorkTimeRecord: function () {
            return {
                id: -1,
                user: null,
                mode: null,
                cost_center: null,
                start_time: null,
                end_time: null
            }
        },

        getStatusString: function (status) {
            switch (status) {
                case orderStatus.OPEN:
                    return this.$t('orders.status_open')
                case orderStatus.IN_PROGRESS:
                    return this.$t('orders.status_in_progress')
                case orderStatus.COMPLETED:
                    return this.$t('orders.status_completed')
                case orderStatus.CANCELLED:
                    return this.$t('orders.status_cancelled')
                case orderStatus.PLAN:
                    return this.$t('orders.status_plan')
                case orderStatus.STORED:
                    return this.$t('orders.status_stored')
                case orderStatus.APPROVED:
                    return this.$t('orders.status_approved')
                case orderStatus.INVOICED:
                    return this.$t('orders.status_invoiced')
                case orderStatus.CLOSED:
                    return this.$t('orders.status_closed')
                case orderStatus.ABORTED:
                    return this.$t('orders.status_aborted')
            }
        },

        isCancelled: function (status) {
            return status === orderStatus.CANCELLED
        },
        isCompleted: function (status) {
            return status === orderStatus.COMPLETED
        },
        isOpen: function (status) {
            return status === orderStatus.OPEN
        },
        isInProgress: function (status) {
            return status === orderStatus.IN_PROGRESS
        },
        isPlan: function (status) {
            return status === orderStatus.PLAN
        },
        isStored: function (status) {
            return status === orderStatus.STORED
        },
        isApproved: function (status) {
            return status === orderStatus.APPROVED
        },
        isInvoiced: function (status) {
            return status === orderStatus.INVOICED
        },
        isClosed: function (status) {
            return status === orderStatus.CLOSED
        },
        isAborted: function (status) {
            return status === orderStatus.ABORTED
        },

        jsonToItem: function (item) {
            let copy = JSON.parse(JSON.stringify(item))
            if (!copy.area_group) {
                copy.area_group = {
                    id: null
                }
            }
            if (!copy.inspections) {
                copy.inspections = []
            }
            if (!copy.certificate_types) {
                copy.certificate_types = []
            }
            return copy
        },

        getUnitText: function (unit) {
            switch (unit) {
                case units.HOUR:
                    return this.$t('contracts.unit_hours')
                case units.DAY:
                    return this.$t('contracts.unit_days')
                case units.WEEK:
                    return this.$t('contracts.unit_weeks')
                case units.MONTH:
                    return this.$t('contracts.unit_months')
                case units.TONS:
                    return this.$t('contracts.unit_tons')
                case units.METERS:
                    return this.$t('contracts.unit_meters')
                case units.KILOMETERS:
                    return this.$t('contracts.unit_km')
                case units.SQUARE_METERS:
                    return this.$t('contracts.unit_sq_meters')
                case units.CUBIC_METERS:
                    return this.$t('contracts.unit_cb_meters')
                case units.PCS:
                    return this.$t('contracts.unit_pcs')
            }
            return '-'
        },
        getStatusColorVariant: function (status) {
            switch (true) {
                case this.isCancelled(status):
                    return 'status-cancelled'
                case this.isCompleted(status):
                    return 'status-completed'
                case this.isOpen(status):
                    return 'status-open'
                case this.isInProgress(status):
                    return 'status-in-progress'
                case this.isPlan(status):
                    return 'status-plan'
                case this.isStored(status):
                    return 'status-stored'
                case this.isApproved(status):
                    return 'status-approved'
                case this.isInvoiced(status):
                    return 'status-invoiced'
                case this.isClosed(status):
                    return 'status-closed'
                case this.isAborted(status):
                    return 'status-aborted'
            }
            return ''
        }
    }
}
