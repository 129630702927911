<template>
<div>
    <b-row class="pl-3 pr-1 pt-2 pb-1">
        <!-- PHOTOS -->
        <div class="col-sm-4 nopads p-1">
                <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="vehicle && vehicle.photos && vehicle.photos.length > 0" class="pointer nopads text-center"
                          style="height: 15em;">
                    <div v-for="photo in vehicle.photos" :key="photo.id" class="relative nopads stat-map-container slide-img-container">
                        <ImageView
                            ref="image"
                            :url="getUrlForPhoto(photo)"
                            @click="openImage"
                        />
                    </div>
                    <div slot="viewport" class="flicking-pagination"></div>
                </Flicking>
                <div v-else style="padding-top: 6em; text-align: center">{{ $t('power_stations.no_photos') }}</div>
        </div>
        <!-- MAP -->
        <div
            v-if="vehicleLocation"
            class="col-sm-8 nopads pl-1 pr-1 pt-1">
            <div
            class="col-sm-12 stat-map-container nopads"
            style="overflow: hidden; height: 15em;"
        >
            <div
                v-if="!fullScreenPhotoData"
                class="col-12"
                style="position: relative; top: 0; left: 0; right: 0; z-index: 20;  background-color: rgba(255, 255, 255, .5); line-height: 2em; font-weight: bold">
                {{ lastLocationTime }}
            </div>
            <marker-editor-map
                v-if="vehicleLocation"
                :lat="vehicleLocation.y"
                :lon="vehicleLocation.x"
                :type="VEHICLE"
                :vehicleType="vehicle.type"
                :fresh="true"
                :draggable="false"
            />
        </div>
        </div>
    </b-row>
    <b-row class="col-sm-12">
        <div class="col-xl-4 nopads p-1">
            <!--TODAY-->
            <vehicle-statistic-values
                :header="$t('vehicle_statistics.today')"
                :loading="loading"
                :work-distance="todayWorkDistance"
                :resettle-distance="todayResettleDistance"
                :work-time="todayWorkTime"
                :resettle-time="todayResettleTime"
                :utilization-rate="todayUtilizationRate"/>
        </div>
        <div class="col-xl-4 nopads p-1">
            <vehicle-statistic-values
                :header="$t('vehicle_statistics.week')"
                :loading="loading"
                :work-distance="weekWorkDistance"
                :resettle-distance="weekResettleDistance"
                :work-time="weekWorkTime"
                :resettle-time="weekResettleTime"
                :utilization-rate="weekUtilizationRate"/>
        </div>
        <div class="col-xl-4 nopads p-1">
            <vehicle-statistic-values
                :header="$t('vehicle_statistics.month')"
                :loading="loading"
                :work-distance="monthWorkDistance"
                :resettle-distance="monthResettleDistance"
                :work-time="monthWorkTime"
                :resettle-time="monthResettleTime"
                :utilization-rate="monthUtilizationRate"/>
        </div>
    </b-row>
    <!-- Fullscreen photo -->
    <transition name="fade">
        <fullscreen-image
            v-if="fullScreenPhotoData"
            :largeImages="fullScreenPhotoData"
            :panelIndex="panelIndex"
            @close="closePhoto"/>
    </transition>
</div>

</template>



<script>
import {vehicleHelper} from '../../mixins/VehicleMixin'
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import VehicleStatisticValues from "@/components/vehicle/statistic/VehicleStatisticValues";
import MarkerEditorMap from "@/components/map/MarkerEditorMap";
import {mapHelper} from "@/components/mixins/MapMixin";
import FullscreenImage from "@/components/view/FullscreenImage";
import {observationHelper} from "@/components/mixins/ObservationMixin";
import {Pagination} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/vue-flicking/dist/flicking.css";
import {Flicking} from "@egjs/vue-flicking";
import ImageView from "@/components/view/ImageView.vue";

export default {
    name: 'VehicleStatistic',
    components: {ImageView, FullscreenImage, MarkerEditorMap, VehicleStatisticValues, Flicking},
    mixins: [vehicleHelper, restApi, timeUtils, mapHelper, observationHelper],
    props: {
        isAdmin: Boolean,
        result: {
            type: Object,
            default: null
        },
        vehicle: {
            type: Object,
            default: null
        },
        editMode: Boolean,
        isObserver: Boolean,
    },
    data: function () {
        return {
            plugins: [new Pagination({ type: 'bullet' })],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
            },
            loading: false,
            todayWorkDistance: 0,
            todayResettleDistance: 0,
            todayWorkTime: 0,
            todayResettleTime: 0,
            todayUtilizationRate: 0,
            weekWorkDistance: 0,
            weekResettleDistance: 0,
            weekWorkTime: 0,
            weekResettleTime: 0,
            weekUtilizationRate: 0,
            monthWorkDistance: 0,
            monthResettleDistance: 0,
            monthWorkTime: 0,
            monthResettleTime: 0,
            monthUtilizationRate: 0,
            lastLocationTime: '',
            vehicleLocation: null,
            fullScreenPhotoData: null,
            panelIndex: 0
        }
    },

  mounted() {
    this.fetchTrips()
    this.fetchLocation()
  },

  methods: {
        generateSummaries(trips) {
            trips.forEach(trip => {
                const durationMins = this.getDurationInMinutes(trip.start_time, trip.end_time)
                const isToday = this.isToday(trip.start_time)
                const isThisWeek = this.isThisWeek(trip.start_time)
                const isThisMonth = this.isThisMonth(trip.start_time)
                if (trip.mode === 1) {
                    if (isToday) {
                        this.todayResettleTime += durationMins
                        this.todayResettleDistance += trip.length
                    }
                    if (isThisWeek) {
                        this.weekResettleTime += durationMins
                        this.weekResettleDistance += trip.length
                    }
                    this.monthResettleTime += durationMins
                    this.monthResettleDistance += trip.length
                }
                if (trip.mode === 3) {
                    if (isToday) {
                        this.todayWorkTime += durationMins
                        this.todayWorkDistance += trip.length
                    }
                    if (isThisWeek) {
                        this.weekWorkTime += durationMins
                        this.weekWorkDistance += trip.length
                    }
                    if (isThisMonth) {
                        this.monthWorkTime += durationMins
                        this.monthWorkDistance += trip.length
                    }
                }
            })
            // Utilizations
            const currentDate = new Date();
            const totalMinutes = currentDate.getHours() * 60 + currentDate.getMinutes();
            this.todayUtilizationRate = (this.todayWorkTime + this.todayResettleTime) / totalMinutes
            this.weekUtilizationRate = (this.weekResettleTime + this.weekWorkTime) / this.getMinutesThisWeek()
            this.monthUtilizationRate = (this.monthWorkTime + this.monthResettleTime) / this.getMinutesThisMonth()
        },


        handleResponse: function (response) {
          if (response && response.data) {
            this.generateSummaries(response.data)
          }
          this.loading = false
        },

        fetchTrips: function () {
            this.loading = true
            let monthStart = this.getMonthStart()
            if (this.getMonthStartAsDate().getTime() >= this.getStartOfWeek(this.getToday()).getTime()) {
                const addDays = 7 - this.getMonthStartAsDate().getDay()
                monthStart = new Date(this.getMonthStartAsDate()).setDate(this.getMonthStartAsDate().getDate() - addDays)

            }
            this.params = {type: 1, from: monthStart, vehicle: this.vehicle.id}
            this.restFetchParams(this.tripUrl, this.params, this.handleResponse)
        },

        fetchLocation: function () {
          this.restFetch(this.lastPositionUrl + '/' + this.vehicle.id, this.handleLocationResponse)
         },

        handleLocationResponse: function (response) {
          if (response.data.length > 0) {
              //Add the position
              this.vehicleLocation = {
                  x: response.data[0].position.x,
                  y: response.data[0].position.y
              }
              //Add the time
              this.lastLocationTime = this.toLocalTime(response.data[0].time)
          }
        },
        getUrlForPhoto(photo) {
            return this.vehicleUrl + '/photo/' + this.vehicle.id + '/' + photo.id
        },

      openImage: function () {
          const flicking = this.$refs.flicking
          const imageData = this.$refs.image
          this.fullScreenPhotoData = []
          imageData.forEach(src => {
              this.fullScreenPhotoData.push(src)
          })
          this.panelIndex = flicking.index

      },

        closePhoto() {
            this.fullScreenPhotoData = undefined
        }
    }

}
</script>

<style>
</style>
