<template>
    <b-container fluid
                 class="details-container"
                 @click.stop="allowCancel ? close : ''"
    >
        <div
            v-if="!assignmentMode && observationUpdate"
            class="col-sm-12 dynamic_width"
            @click.stop="cancelClick($event)"
        >
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <div>
                        <h2 class="form-title editor-title" v-if="!observationUpdate || observationUpdate.id < 1">
                            {{ $t('observations.add_observation') }}
                        </h2>
                        <h2 class="form-title editor-title" v-else>
                            {{ $t('observations.edit_observation') }}
                        </h2>
                    </div>
                </div>
                <draw-geometry-wizard
                    v-if="showDrawGeometryWizard !== false"
                    :savedGeometry="observationUpdate.geometry"
                    :observationMode="true"
                    @close="closeDrawGeometryWizard"
                    @addDrawnGeometry="addGeometryToObservation"
                />
                <div class="col-12">
                    <h3 v-if="observationUpdate && observationUpdate.id" class="pt-1">
                        {{ observation.type.name  }}
                    </h3>
                    <road-addess-view v-if="!userDefinedGeometry"
                        :road-number="observation.road_number"
                        :section-number="observation.section_number"
                        :distance="observation.distance_from_sec_start"
                        :street-name="observation.street_name"
                    />
                    <div>
                        <span class="span-title">{{ $t('observations.observation_group') }}</span>
                        <b-form-select
                            id="group"
                            v-model="group"
                            size="sm"
                            class="mb-0"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('observations.select_group') }}
                                </option>
                            </template>
                            <option
                                v-for="item in groups"
                                :key="item.id"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>

                        <span class="span-title">{{ $t('observation_filter.observation_type') }}</span>
                        <b-form-select
                            id="observationType"
                            v-model="observationUpdate.type"
                            size="sm"
                            class="sm-3"
                            :disabled="!types"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('observation_filter.select_observation_type') }}
                                </option>
                            </template>
                            <option
                                v-for="option in types"
                                :key="option.id"
                                :value="option"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <b-row>
                    <!-- Location editable if observation is populated -->
                    <div class="col-sm-6 nopads pl-1 p-3 pt-1">
                        <div
                            class="col-sm-12 stat-map-container nopads"
                            style="overflow: hidden; height: 15em;">
                            <marker-editor-map
                                :id="observationUpdate.id"
                                :lat="getLatitude(this.observationUpdate)"
                                :lon="getLongitude(this.observationUpdate)"
                                :points="getPoints(this.observationUpdate)"
                                :type="OBSERVATION"
                                :observation-mode="true"
                                :draggable="false"
                            />
                        </div>
                    </div>
                    <!-- PHOTOS -->
                    <div class="col-sm-6 nopads pl-1 p-3 pt-1">
                        <div class="stat-container nopads">
                            <Flicking ref="flicking" :options="options" :plugins="plugins" class="pointer nopads text-center"
                                      style="height: 15em;"
                                      v-if="observationUpdate.photos && observationUpdate.photos.length > 0"
                            >
                                <div slot="viewport" class="flicking-pagination"></div>
                                    <ImageView
                                        v-for="photo in observationUpdate.photos" :key="photo.id"
                                        ref="image"
                                        class="flicking-panel"
                                        :contain="true"
                                        :url="getPhotoUrl(observationPhotoUrl, photo)"
                                        @click="openImage"
                                    />
                            </Flicking>
                            <div v-else style="padding-top: 6em; text-align: center">{{ $t('power_stations.no_photos') }}</div>
                        </div>
                    </div>


                </b-row>
                <b-row class="nopads pt-2 pl-2" v-if="observationUpdate.geometry">
                        <b-button
                            variant="primary"
                            class="form-button"
                            size="sm"
                            @click.stop="showDrawGeometryWizard = !showDrawGeometryWizard"
                        >
                            {{ $t('map.redefine_geometry') }}
                        </b-button>
                </b-row>
                <hr/>
                <h4 class="form-header pl-3 pr-4">{{ $t('contracts.basic_info')}}</h4>
                <b-row class="pl-2 pr-2">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t("observations.reporter") }}</span>
                        <span v-if="observationUpdate.reporter">{{ observationUpdate.reporter.first_name }} {{ observationUpdate.reporter.last_name }}</span>
                        <span v-else>-</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t("observations.created_time") }}</span>
                        <span>{{ toLocalTime(observationUpdate.created_time) }}</span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pl-2 pr-0">
                        <open-contract-filter
                            :to="observationUpdate.created_time"
                            :from="now"
                            :show-company="false"
                            :show-title="true"
                            :showOnlyMyContractsOption="true"
                            :contract="observationUpdate.contract"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>
                    <div class="col-sm-6 nopads pr-2 pt-1">
                        <!-- order selection -->
                        <span class="span-title">{{ $t('observations.orders') }}</span>
                        <b-form-select
                            id="order"
                            v-model="orderId"
                            size="sm"
                            class="sm-3"
                            :disabled="orders.length < 1"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('observations.select_order') }}
                                </option>
                            </template>
                            <option
                                v-for="option in orders"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ getOrderStringWithInfo(option) }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>

                <div class="col-sm-12">
                    <span class="span-title">{{ $t('observations.closed_time') }}</span>
                    <div
                        v-if="observationUpdate.closed_time"
                        class="col-sm-6 nopads"
                    >
                        <input
                            class="mr-2"
                            id="checkbox"
                            v-model="open"
                            type="checkbox"
                        >
                        <label for="checkbox">{{ $t('observations.open') }}</label>
                    </div>
                    <div class="col-sm-6 nopads pr-1">
                        <datetime
                            v-model="observationUpdate.closed_time"
                            type="datetime"
                            style="font-size: .9em; line-height: 1.4em"
                            format="dd.MM.yyyy HH:mm"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </div>

                <b-row class="nopads">
                    <!-- Value -->
                    <b-row class="col-sm-6 nopads pl-3 pr-1">
                        <div class="col-10 nopads">
                            <span class="span-title">{{ $t('observations.value') }}</span>
                            <b-form-input
                                id="value"
                                v-model="observationUpdate.value"
                                type="number"
                                :disabled="!(observationUpdate && observationUpdate.type && observationUpdate.type.numeric_info)"

                                size="sm"
                            />
                        </div>
                        <div class="col-2 nopads pl-2 text-center" style="padding-top: 1.8em">
                            {{ observationUpdate && observationUpdate.type && observationUpdate.type.numeric_unit ? observationUpdate.type.numeric_unit : '-' }}
                        </div>
                    </b-row>
                    <div class="col-sm-6">
                        <span class="span-title">{{ $t('observations.public') }}</span>
                        <input
                            class="mr-2 mt-2"
                            id="checkbox_public"
                            v-model="observationUpdate.is_public"
                            type="checkbox"
                        >
                        <label for="checkbox">{{ $t('observations.public_hint') }}</label>
                    </div>
                </b-row>

                <!-- Info -->
                <div class="col-sm-12">
                    <span class="span-title">{{ $t('work_time.note') }}</span>
                    <b-form-textarea
                        id="info"
                        v-model="observationUpdate.info"
                        :placeholder="$t('work_time.note')"
                        :rows="4"
                        size="sm"
                        :max-rows="8"
                    />
                </div>

                <hr/>
                <div class="nopads" v-if="allowExternalEquipments">
                    <h4 class="form-header pl-3 pr-4">{{ $t('observations.external_equipment')}}</h4>
                    <b-row class="col-12 nopads pt-2">
                        <div class="col-sm-5">
                            <span class="span-title">{{ $t('observations.external_equipment') }}</span>
                            <span class="item-detail-text">{{ observationUpdate.external_system_equipment_id ?  observation.external_system_equipment_id : '-'}}</span>
                        </div>
                        <div class="col-sm-5">
                            <span class="span-title">{{ $t('observations.external_system') }}</span>
                            <span class="item-detail-text">{{ observationUpdate.external_system_id ?  getExternalSystemName(observation.external_system_id) : '-'}}</span>
                        </div>
                        <div class="col-sm-2 pt-4 text-right">
                            <b-button
                                v-if="allowCancel"
                                variant="info"
                                class="result-button"
                                size="sm"
                                @click.stop="searchEquipment"
                            >
                                {{ $t('common.search') }}
                            </b-button>
                        </div>
                    </b-row>
                    <transition name="fade">
                        <b-row v-if="externalEquipments.length > 0" style="padding-right: .75em; padding-left: .75em">
                            <external-equipment-selector
                                v-model="externalEquipment"
                                :equipments="externalEquipments"
                                :selected="observationUpdate.external_system_equipment_id"
                            />
                        </b-row>
                    </transition>
                    <hr/>
                </div>

                <!-- Observation photo -->
                <h4 class="form-header pl-3 pr-4">{{ $t('power_stations.photos')}}</h4>
                <div class="col-12 p-2">
                    <span class="span-title pl-1">{{ $t('observations.add_photo') }}</span>
                    <div
                        v-for="(path, counter) in newPhotos"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(newPhotos, counter-1)"
                        >
                            <b-form-file
                                v-model="newPhotos[counter]"
                                size="sm"
                                accept="image/jpeg, image/jpg, image/png"
                                :placeholder="$t('equipment.photo_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </div>
                <hr/>
                <div
                    v-if="observationUpdate && observationUpdate.id > 0 && allowAssign"
                    class="col-sm-12"
                >
                    <hr/>
                    <h4 class="form-header">{{ $t('work_assignment.title')}}</h4>
                    <work-assignment-view
                        :type="3"
                        :id="observationUpdate.id"
                        :can-edit="true"
                        :can-add="isObserver"
                        @editAssignment="editAssignment"
                    />
                </div>
                <hr/>
                <div class="col-sm-12 button-container pb-3">
                    <b-button
                        v-if="allowCancel"
                        variant="danger"
                        class="result-button"
                        size="sm"
                        @click.stop="close"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        :disabled="loading"
                        class="result-button"
                        size="sm"
                        @click.stop="submitObservation"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>

        <div
            v-if="isObserver && assignmentMode"
            class="col-sm-12 nopads"
            @click.stop="cancelClick($event)"
        >
            <transition v-if="isObserver && assignmentMode" name="fade">
                <work-assignment-editor
                    v-if="isObserver && assignmentMode"
                    :observation="observationUpdate"
                    :is-observer="isObserver"
                    :via-observation="true"
                    :work-assignment="workAssignment"
                    :dynamic="true"
                    @close="setAssignmentEditorVisibility(false)"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>
        </div>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :largeImages="fullScreenPhotoData"
                :panelIndex="panelIndex"
                @close="closePhoto"/>
        </transition>

    </b-container>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from '../mixins/MapMixin'
import WorkAssignmentEditor from '../workassignment/WorkAssignmentEditor'
import WorkAssignmentView from '../workassignment/WorkAssignmentView'
import {attachmentHelper} from '../mixins/AttachmentMixin'
import {orderMixin} from "@/components/mixins/OrderMixin";
import {workAssignmentGeomTypes, workAssignmentHelper} from '../mixins/WorkAssignmentMixin'
import {observationHelper} from "@/components/mixins/ObservationMixin";
import DrawGeometryWizard from "@/components/shapeimport/DrawGeometryWizard";
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {getDistance} from 'geolib';
import ExternalEquipmentSelector from "@/components/velho/ExternalEquipmentSelector";
import FullscreenImage from "@/components/view/FullscreenImage";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {Pagination} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/vue-flicking/dist/flicking.css";
import {Flicking} from "@egjs/vue-flicking";
import ImageView from "@/components/view/ImageView.vue";
import RoadAddessView from "@/components/velho/RoadAddressView";

export default {
    name: 'ObservationEditor',
    components: {
        RoadAddessView,
        Flicking,
        ImageView,
        FullscreenImage,
        ExternalEquipmentSelector,
        OpenContractFilter,
        MarkerEditorMap,
        WorkAssignmentEditor, WorkAssignmentView, DrawGeometryWizard},
    mixins: [restApi, mapHelper, attachmentHelper, orderMixin, observationHelper, workAssignmentHelper,
        workAssignmentGeomTypes, conversionModelHelper, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: Boolean,
        allowCancel: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        viaWorkAssignment: {
            type: Boolean,
            default: false
        },
        oldWorkAssignment: {
            type: Object,
            default: null
        },
        allowAssign: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        observation: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            options: {
                preventDefaultOnDrag: true,
                circular: true
            },
            plugins: [new Pagination({ type: 'bullet' })],
            loading: false,
            observationUpdate: null,
            contracts: [],
            orders: [],
            fullScreenPhotoData: null,
            typeFilter: null,
            types: [],
            groups: [],
            externalEquipments: [],
            externalEquipment: null,
            open: false,
            allowExternalEquipments: false,
            group: null,
            orderId: null,
            contract: null,
            newPhotos: new Array(5),
            showDrawGeometryWizard: false,
            userDefinedGeometry: false,
            assignmentMode: false,
            workAssignment: null,
            panelIndex: 0
        }
    },
    computed: {
        now() {
            return new Date().toISOString();
        }
    },
    watch: {
        group(val) {
            if (val && !(this.observations && this.observations.length === 1)) {
                this.fetchObsTypes()
            }
        },
        contract: function () {
            this.observationUpdate.contract = this.contract
            if (this.observationUpdate.contract) {
                this.allowExternalEquipments = this.contract.external_contract_id
                this.fetchOrders()
            } else {
                this.orders = []
                this.allowExternalEquipments = false
            }
        },
        orderId() {
            if (this.orderId) {
                this.observationUpdate.work_order = {id: this.orderId}
            } else {
                this.observationUpdate.work_order = null
            }
        },
        externalEquipment(val) {
            this.observationUpdate.external_system_id = val ? val.system : null
            this.observationUpdate.external_system_equipment_id = val? val.id : null
        }
    },
    created: function () {
        if (this.observation) {
            this.observationUpdate = this.observation
            this.contract = this.observationUpdate.contract
            this.orderId = this.observationUpdate.work_order ? this.observationUpdate.work_order.id : null
            if(this.observationUpdate.type && this.observationUpdate.type.observation_group) {
                this.group = this.observationUpdate.type.observation_group.id
            }
        } else {
            this.observationUpdate = {
                id: 0,
                type: null,
            }
        }
        this.getGeometryType()
        this.fetchObsGroups()
        if (this.contract) {
            this.allowExternalEquipments = this.contract.external_contract && this.contract.external_contract.id
        }
    },
    methods: {
        fetchObsGroups: function () {
            this.groups = []
            this.loading = true
            this.restFetch(this.observationGroupUrl, this.handleGroupResponse)
        },
        handleGroupResponse: function (response) {
            this.groups = response.data
            this.loading = false
        },
        fetchObsTypes: function () {
            this.type = null
            this.types = []
            this.loading = true
            this.restFetchParams(this.observationTypeUrl, {group: this.group, geometry_type: this.typeFilter}, this.handleTypeResponse)
        },
        handleTypeResponse: function (response) {
            this.types = response.data
            this.loading = false
        },

        getGeometryType() {
            if(this.oldWorkAssignment) {
                this.typeFilter = this.getWorkAssignmentGeometryType(this.oldWorkAssignment)
            } else if(this.observationUpdate) {
                this.typeFilter = this.getObservationGeometryType(this.observationUpdate)
            }
        },

        setContract: function (contract) {
            this.contract = contract
        },
        fetchOrders: function () {
            this.order = null
            this.loading = true
            var params = {}
            if (this.observationUpdate.contract) {
                params.contract = this.observationUpdate.contract.id
            }
            params.open = 1 // Get orders only for
            // open or in progress contracts
            this.restFetchParams(this.orderUrl, params, this.handleOrders)
        },
        handleOrders: function (response) {
            this.orders = response.data
            this.loading = false
        },
        submitObservation: function () {
            this.loading = true
            if (this.observation.id && this.observation.id > 0) {
                this.restUpdate(this.observationUrl, this.observationUpdate, this.success, this.fail)
            } else {
                this.restAdd(this.observationUrl, this.observationUpdate, this.success, this.fail)
            }
        },
        success: function (response) {
            if(response && response.id || response !== 'ok') {
                this.observationUpdate = response.id ? response : null
            }
            var photos = this.getValidAttachments(this.newPhotos)
            if (photos.length > 0 && this.observation) {
                this.uploadPhoto(photos)
            } else {
                this.loading = false
                this.$emit('closeAndUpdate', this.observation)
            }
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        close: function () {
            this.assignmentMode = false
            this.$emit('close')
        },
        uploadPhoto: function (photos) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                formData.append('observation_id', this.observation.id)
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.observationPhotoUrl, {}, formData, this.photoSuccess, this.fail)
            }
        },
        photoSuccess: function () {
            this.loading = false
            this.$emit('closeAndUpdate', this.observation)
        },
        cancelClick: function (e) {
            e.stopPropagation()
        },
        editAssignment: function (assignment) {
            if(this.allowAssign) {
                this.workAssignment = assignment
                this.setAssignmentEditorVisibility(true)
                this.$emit('scrollTop')
            } else {
                this.$emit('close')
            }
        },
        setAssignmentEditorVisibility: function (visible) {
            this.assignmentMode = visible
        },
        addGeometryToObservation(geometry) {
            if(geometry && geometry.point && geometry.point.lat && geometry.point.lon) {
                this.observation.geometry = {point: {y: geometry.point.lat, x: geometry.point.lon}}
            } else if(geometry.line_string && geometry.line_string.points) {
                this.observation.geometry = geometry
            }
            this.closeDrawGeometryWizard()
            this.userDefinedGeometry = true
        },
        closeDrawGeometryWizard() {
            this.showDrawGeometryWizard = false
        },
        closeAndUpdate: function (assignment) {
            this.setAssignmentEditorVisibility(false)
            this.workAssignment = assignment
        },
        searchEquipment() {
            const params = {
                lat: this.observationUpdate.geometry.point.y,
                lon: this.observationUpdate.geometry.point.x
            }
            this.loading = true
            this.restFetchParams(this.extEquipmentSearchUrl, params, this.handleEquipments)
        },
        handleEquipments(response) {
            this.loading = false
            if (response && response.data) {
                this.initEquipmentOptions(response.data)
            } else {
                this.externalEquipments = []
            }
        },
        initEquipmentOptions(data) {
            this.externalEquipments = []
            const tempResults = []
            const keys = Object.keys(data)
            keys.forEach(key => {
                const items = data[key]
                const type = this.getTypeCodeForKey(key)
                items.forEach(eq => {
                    tempResults.push({
                        "id": eq.external_system_id,
                        "type": type,
                        "system": eq.external_system,
                        "name": this.getEquipmentTypeName(type),
                        "code": eq.sign ? eq.sign : null,
                        "distance": eq.position ? getDistance(
                            {latitude: this.observationUpdate.geometry.point.x,longitude: this.observationUpdate.geometry.point.y},
                            {latitude: eq.position.x,longitude: eq.position.y}) : null
                        })
                })
            })
            tempResults.sort((a, b) => {
                if (a.distance === null && b.distance === null) {
                    return 0; // If both are null, consider them equal
                } else if (a.distance === null) {
                    return 1; // Null should come after non-null values
                } else if (b.distance === null) {
                    return -1; // Null should come after non-null values
                } else {
                    return a.distance - b.distance; // Sort based on the numeric attribute
                }
            });
            this.externalEquipments = tempResults
        },
        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index

        },
        closePhoto() {
            this.fullScreenPhotoData = null
        }
    }
}
</script>
<style scoped>
.map-container {
    height: 30em;
    position: relative;
}

.flicking-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height of the Flicking container */
    width: 100%; /* Full width of the Flicking container */
}

.flicking-panel-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures image fits within the container and is fully visible */
}

</style>

