<template>
    <div class="login-container">
        <form
            class="form-signin"
            @submit.prevent="login"
        >
            <b-row>
                <img src="../../assets/routa_light_S.png" style="display: block; margin-left: auto; margin-right: auto; width: auto; height: 6em; margin-bottom: 1em" alt="logo"/>
            </b-row>
            <h2 class="form-signin-heading">
                Routa Demo
            </h2>
            <b-row>
                <b-form-input
                    id="inputUsername"
                    v-model="username"
                    type="text"
                    :placeholder="$t('login.username')"
                    required
                    autocomplete="username"
                    autofocus
                />
            </b-row>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="inputPassword"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        :placeholder="$t('users.password')"
                        required
                        autocomplete="current-password"
                        class="mb-2"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            @mousedown="showPassword = true"
                            @mouseup="showPassword = false"
                            @mouseout="showPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-button
                type="submit"
                size="lg"
                variant="primary col-sm-12"
                style="background-color: #7FC000; border-color: #7FC000"
            >
                {{ $t('login.log_in') }}
            </b-button>
            <b-row>
                <div
                    class="forgot"
                    @click.stop="$emit('resetPassword')"
                >
                    {{ $t('login.forgot_password') }}
                </div>
            </b-row>
            <b-row v-if="externalMessage">
                <span style="font-size: .9em; color: #fff" >{{ externalMessage }}</span>
            </b-row>
            <b-alert
                class="alert"
                :show="alertDismissCountDown"
                dismissible
                fade
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
            >
                {{ alertMessage }}
            </b-alert>
            <b-alert
                class="alert"
                :show="noteDismissCountDown"
                dismissible
                fade
                variant="info"
                @dismissed="noteDismissCountDown=0"
                @dismiss-count-down="noteCountDownChanged"
            >
                {{ message }}
            </b-alert>
        </form>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'Login',
    mixins: [restApi],
    data() {
        return {
            username: '',
            password: '',
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            noteDismissCountDown: 0,
            alertMessage: '',
            message: '',
            showPassword: false,
        }
    },
    props: {
        externalMessage: {
            type: String,
            default: null
        }
    },
    mounted() {
        this.showPassword = false
    },
    methods: {
        async login() {
            this.alertDismissCountDown = 0
            this.loading = true
            const { data: { token } } = await this.axios.post(this.loginUrl, {
                username: this.username,
                password: this.password
            }).catch(() => this.fail())
            this.loading = false
            this.$store.commit('storeToken', token)
            // Read user prefs from backend
            await this.loadUserSettings();
            // read the map key
            const { data: mapKey } =  await this.axios.get(this.mapKeyUrl).catch(() => this.fail());
            this.$store.commit('storeMapKey', mapKey);
            this.$store.commit('storeMapEnv', 'demo');
            this.$emit('closeLoginForm')
        },

        async loadUserSettings() {
            // Later other settings could be possible too, now we only have map settings
            try {
                const response = await this.fetchUserPreferences('mapSettings');
                const mapSettings = response && response.data.preferences;
                if (mapSettings) {
                    this.$store.commit('storeMapSettings', mapSettings);
                }
            }
            catch (e) {
                const mapSettings = { baseMap: 'LEAFLET' };
                this.$store.commit('storeMapSettings', mapSettings);
            }
        },


        fail: function () {
            this.loading = false
            this.showAlert(this.$t('login.error'))
        },

        showAlert: function (message) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
        },

        alertCountDownChanged: function (dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },

        showResetTip: function () {
            this.noteDismissCountDown = this.alertDismissSecs
            this.message = this.$t('login.reset_done')
        },

        noteCountDownChanged: function (dismissCountDown) {
            this.noteDismissCountDown = dismissCountDown
        }

    }
}
</script>
<style>
@import "../../styles/login.css";
::-ms-reveal {
    display: none;
}
</style>
