<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <b-row class="p-2">
                    <area-filter
                        ref="areaFilter"
                        :is-admin="isAdmin"
                        @areaSet="setArea"
                        @companySet="setCompany"
                    />
                </b-row>
                <b-row>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('common.start_date') }}</span>
                        <b-form-datepicker
                            v-model="startDate"
                            size="sm"
                            class="sm-3"
                            :locale="this.$i18n.locale"
                            :label-no-date-selected="$t( 'contract_user_reports.choose_date' )"
                            :placeholder="$t( 'contract_user_reports.choose_date' )"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            id="start_time">
                        </b-form-datepicker>
                    </div>
                    <div class="col-sm-4">
                        <span class="search-title">{{ $t('common.end_date') }}</span>
                        <b-form-datepicker
                            v-model="endDate"
                            size="sm"
                            class="sm-3"
                            id="end_time"
                            :label-no-date-selected="$t( 'contract_user_reports.choose_date' )"
                            :locale="this.$i18n.locale"
                            :placeholder="$t( 'contract_user_reports.choose_date' )"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                        </b-form-datepicker>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        :disabled="(isAdmin && !company)"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import AreaFilter from '../AreaFilter'

export default {
    name: 'AreaReportFilter',
    components: {AreaFilter},
    mixins: [restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            name: null,
            area: null,
            company: null,
            startDate: null,
            endDate: null,
        }
    },
    methods: {
        onReset: function () {
            this.name = null
            this.area = null
            this.startDate = null
            this.endDate = null
            this.company = null
            this.$refs.areaFilter.resetData()
        },
        onSubmit: function () {
            let params = {}
            if (this.area) {
                params.area = this.area.id
            }
            if (this.company) {
                params.company = this.company
            }
            if (this.startDate) {
                params.from = this.startDate
            }
            if (this.endDate) {
                params.to = this.endDate
            }
            this.$emit('search', params)
        },

        setArea: function (area) {
            this.area = area
        },

        setCompany: function (company) {
            this.company = company
        }
    }
}
</script>
